import Content from "../../../components/Content/Content";
import ContentBody from "../../../components/Content/ContentBody";
import ContentHead from "../../../components/Content/ContentHead";
import FieldsContainer, {
  FieldCard,
} from "../../../components/FieldsContainer";
import InputField from "../../../components/InputField";
import { BLOG_UPDATE } from "../../../utils/constants";
import styles from "../../../styles/blog.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faPlus } from "@fortawesome/free-solid-svg-icons";
import TextEditor from "../../../components/TextEditor";
import React from "react";
import { ReactComponent as Close } from "../../../assets/cross.svg";
import withRouter from "../../../components/withRouter";
import { toast } from "react-hot-toast";
import { getFileName } from "../../../utils/getFileName";
import UploadImage from "../../../utils/UploadImage";
import api from "../../../api";

type IState = {
  [key: string]: any;
  clientName: string;
  clientBrand: string;
  title: string;
  shortDescription: string;
  type: string;
  category: string;
  tag: string;
  tags: string[];
  mediaUrl: any[];
  attachmentUrl: any;
  body: string;
  visibility: string;
  loading: boolean;
};

class AddUpdateBlog extends React.Component<any, IState> {
  state = {
    clientName: "",
    clientBrand: "",
    title: "",
    shortDescription: "",
    type: "",
    category: "",
    tag: "",
    tags: [],
    mediaUrl: [],
    attachmentUrl: "",
    body: "",
    visibility: "",
    loading: false,
  };

  componentDidMount(): void {
    if (this.props.router.params.id) {
      this.setState({ loading: true });
      this.getData();
    } else {
      this.setState({ loading: false });
    }
  }

  getData = () => {
    api({
      url: "content/getContentById/" + this.props.router.params.id,
      method: "get",
      data: {},
      header: false,
    })
      .then((res: any) => {
        console.log(res.data.data);
        let data = res.data.data;

        this.setState({
          loading: false,
          type: data.typeName,
          visibility: data.visibility,
          title: data.title,
          body: data.body,
          category: data.category,
          tags: data.tags,
          shortDescription: data.shortDescription,
          mediaUrl: [
            {
              url: data.mediaUrl,
              obj: "",
              type: "",
            },
          ],
          clientName: data?.clientName,
          clientBrand: data?.clientBrand,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  handleChange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleFiles = (e: any) => {
    if (this.state.mediaUrl.length === 1) {
      toast.error("For now you can only upload one image");
    } else {
      this.setState({
        mediaUrl: [
          ...this.state.mediaUrl,
          {
            url: URL.createObjectURL(e.target.files[0]),
            obj: e.target.files[0],
            type: e.target.files[0].type.includes("image") ? "img" : "pdf",
          },
        ],
      });
    }
  };

  handleClick = (e: any) => {
    if (e.target.name === "btn1") {
      if (this.props.router.params.id) {
        this.updateContent();
      } else {
      this.createContent();
      }
      
    } else if (e.target.name === "btn3") {
      this.props.router.navigate("/");
    }
  };

  uploadMediaUrl = () => {
    let temp: any = this.state.mediaUrl;

    return new Promise((resolve, reject) => {
      let temp_url: any = [];

      for (let i = 0; i < temp.length; i++) {
        if (temp[i].obj) {
          const filename = getFileName(temp[i].obj);

          try {
            let _ = UploadImage({ file: temp[i].obj, name: filename });
            temp_url.push(filename);
          } catch (err) {
            toast.error("error occurred while uploading media url.");
            reject(err);
          }
        } else {
          temp_url.push(temp[i].url);
        }
      }

      resolve(temp_url);
    });
  };

  createContent = () => {
    this.setState({ loading: true });

    console.log(
      "these are the values which we are going to addd ::: ",
      this.state
    );

    this.uploadMediaUrl()
      .then((res: any) => {
        console.log("got this response after upload image :::::: ", res);
        api({
          url: "content/addContent",
          method: "post",
          data: {
            clientName: this.state.clientName,
            clientBrand: this.state.clientBrand,
            title: this.state.title,
            shortDescription: this.state.shortDescription,
            typeName: this.state.type,
            category: this.state.category,
            tags: [...this.state.tags],
            mediaUrl: res.length !== 0 ? res[0] : "",
            attachmentUrl: "",
            body: this.state.body,
            visibility: this.state.visibility,
          },
          header: true,
        })
          .then(() => {
            this.setState({ loading: false });
            toast.success("Content Created Successfully");
            this.props.router.navigate("/");
          })
          .catch(() => {
            this.setState({ loading: false });
            toast.error("Error occurred while creating content");
          });
      })
      .catch(() => {});
  };

  updateContent = () => {
    this.setState({ loading: true });

    this.uploadMediaUrl()
      .then((res: any) => {
        api({
          url: "content/updateContent/" + this.props.router.params.id,
          method: "patch",
          data: {
            clientName: this.state.clientName,
            clientBrand: this.state.clientBrand,
            title: this.state.title,
            shortDescription: this.state.shortDescription,
            typeName: this.state.type,
            category: this.state.category,
            tags: [...this.state.tags],
            mediaUrl: res.length !== 0 ? res[0] : "",
            attachmentUrl: "",
            body: this.state.body,
            visibility: this.state.visibility,
          },
          header: true,
        })
          .then(() => {
            this.setState({ loading: false });
            toast.success("Content Updated Successfully.");
            this.props.router.navigate("/");
          })
          .catch(() => {
            this.setState({ loading: false });
            toast.error("Error occurred while updating content.");
          });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <>
        {this.state.loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: "rgba(0,0,0,.3)",
              zIndex: 100000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="loader"></span>
          </div>
        )}
        <Content>
          <ContentHead
            title={BLOG_UPDATE}
            showBtn1={true}
            // showBtn2={true}
            showBtn3={true}
            btn1Name="btn1"
            btn2Name="btn2"
            btn3Name="btn3"
            btn1Text="Save"
            btn2Text="Save as Draft"
            btn3Text="Cancel"
            btn1type="filled"
            btn2type="outlined"
            btn3type="light_grey"
            onClickHandler={this.handleClick}
          />
          <ContentBody>
            <FieldsContainer>
              {/* Blogs details - title, type, category, tags */}
              <FieldCard take="1/4" heading={"Details"}>
                <InputField
                  isLabel={true}
                  name="type"
                  lable="Content Type"
                  type={"select"}
                  placeholder="Select Content Type"
                  value={this.state.type}
                  options={options}
                  accessText="title"
                  accessValue="value"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  name="clientName"
                  value={this.state.clientName}
                  type="text"
                  lable="Client Name"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  name="clientBrand"
                  value={this.state.clientBrand}
                  type="text"
                  lable="Client Brand"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  name="title"
                  value={this.state.title}
                  type="text"
                  lable="Title"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  name="category"
                  value={this.state.category}
                  type="text"
                  lable="Category"
                  onChange={this.handleChange}
                />
                <div className={styles.tags_container}>
                  <InputField
                    isLabel={true}
                    name="tag"
                    value={this.state.tag}
                    type="text"
                    lable="Tags"
                    onChange={this.handleChange}
                    onKeyPress={(e: any) => {
                      if (e.key === "Enter") {
                        this.setState({
                          tags: [...this.state.tags, this.state.tag],
                          tag: "",
                        });
                      }
                    }}
                  />
                  <div className={styles.tags}>
                    {this.state.tags.map((val, index) => {
                      return (
                        <p key={index}>
                          {val}
                          <Close
                            onClick={() => {
                              let temp = this.state.tags;
                              temp.splice(index, 1);
                              this.setState({ tags: temp });
                            }}
                          />
                        </p>
                      );
                    })}
                  </div>
                </div>
                <InputField
                  isLabel={true}
                  value={this.state.visibility}
                  type="select"
                  lable="Visibility"
                  name="visibility"
                  options={[
                    { title: "High", value: "high" },
                    { title: "Medium", value: "medium" },
                    { title: "Limited", value: "limited" },
                  ]}
                  accessText="title"
                  accessValue="value"
                  onChange={this.handleChange}
                />
              </FieldCard>

              {/* Blogs Media - media url & attachment url */}
              <FieldCard take="1/4" heading={"Media"}>
                {this.state.mediaUrl.length === 0 ? (
                  <div className={styles.media_container}>
                    <FontAwesomeIcon icon={faPlus} color="#36619b" />
                    <p>Add Media</p>
                    <input type="file" onChange={this.handleFiles} />
                  </div>
                ) : (
                  <div className={styles.medias}>
                    {this.state.mediaUrl.map((val: any, index: any) => {
                      return (
                        <div className={styles.media} key={index}>
                          <img
                            src={
                              this.props.router.params.id
                                ? val.obj
                                  ? val.url
                                  : process.env.REACT_APP_IMAGE + val.url
                                : val.url
                            }
                            alt=""
                          />
                          <div
                            className={styles.media_close}
                            onClick={() => {}}
                          >
                            <FontAwesomeIcon
                              icon={faClose}
                              onClick={() => {
                                let temp = this.state.mediaUrl;
                                temp.splice(index, 1);
                                this.setState({ mediaUrl: temp });
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                    <div className={styles.media}>
                      <div className={styles.add_more_media}>
                        <FontAwesomeIcon icon={faPlus} color="#36619b" />
                        <input type="file" onChange={this.handleFiles} />
                      </div>
                    </div>
                  </div>
                )}
              </FieldCard>

              {/* Blogs Description - short description & description */}
              <FieldCard take="1/4" heading={"Description"}>
                <div className={styles.txt_editor}>
                  <p>Short Description</p>
                  <TextEditor
                    content={this.state.shortDescription}
                    handleChange={(val: any) => {
                      this.setState({ shortDescription: val });
                    }}
                    placeholder={"Add Short Description here"}
                  />
                </div>

                <div className={styles.txt_editor}>
                  <p>Body</p>
                  <TextEditor
                    content={this.state.body}
                    handleChange={(val: any) => {
                      this.setState({ body: val });
                    }}
                    placeholder={"Add Body Content"}
                  />
                </div>
              </FieldCard>
            </FieldsContainer>
          </ContentBody>
        </Content>
      </>
    );
  }
}

const options = [
  { title: "Blog", value: "blog" },
  { title: "Events", value: "events" },
  { title: "Testimonial", value: "testimonial" },
  { title: "Market Trends", value: "marketTrends" },
  { title: "Innovation", value: "innovation" },
  { title: "Trending", value: "trending" },
];

export default withRouter(AddUpdateBlog);
