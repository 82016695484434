import React from "react";
import Content from "../../../../components/Content/Content";
import ContentHead from "../../../../components/Content/ContentHead";
import ContentBody from "../../../../components/Content/ContentBody";
import InputField from "../../../../components/InputField";
import TextEditor from "../../../../components/TextEditor";
import withRouter from "../../../../components/withRouter";
import { COMPLIANCE_NEW, COMPLIANCE_UPDATE } from "../../../../utils/constants";
import api from "../../../../api";
import { toast } from "react-hot-toast";

class AddCompliance extends React.Component<any, any> {
  state = {
    id: "",
    title: "",
    body: "",
    typeName: "",
    company: "",
    allCompliances: [],
    terms: true,
    privacy: true,
    returnPolicy: true,
    cancellationPolicy: true,
    dataPolicy: true,
    loading: true,
    credits: true,
    siteMap: true,
  };

  componentDidMount(): void {
    this.checkPage();
    this.get();
  }

  handleDropDown = () => {
    return [
      {
        title: "Terms",
        value: "terms",
        disabled: !this.state.terms,
      },
      {
        title: "Privacy Policy",
        value: "privacy",
        disabled: !this.state.privacy,
      },
      {
        title: "Data Policy",
        value: "data",
        disabled: !this.state.dataPolicy,
      },
      {
        title: "Cancellation Policy",
        value: "cancellation",
        disabled: !this.state.cancellationPolicy,
      },
      {
        title: "Refund Policy",
        value: "refund",
        disabled: !this.state.returnPolicy,
      },
      {
        title: "Credits",
        value: "credits",
        disabled: !this.state.credits,
      },
      {
        title: "Site Map",
        value: "siteMap",
        disabled: !this.state.siteMap,
      },
    ];
  };

  get = () => {
    api({
      method: "get",
      url: "compliance/getAllCompliance",
      data: {},
      header: false,
    })
      .then((res) => {
        let data = res.data.data.compliance;
        this.setState({ allCompliance: data, loading: false });

        data.forEach((element: any) => {
          if (element.type === "terms") {
            this.setState({ terms: false });
          }
          if (element.type === "privacy") {
            this.setState({ privacy: false });
          }
          if (element.type === "data") {
            this.setState({ dataPolicy: false });
          }
          if (element.type === "cancellation") {
            this.setState({ cancellationPolicy: false });
          }
          if (element.type === "refund") {
            this.setState({ returnPolicy: false });
          }
          if (element.type === "credits") {
            this.setState({ credits: false });
          }
          if (element.type === "siteMap") {
            this.setState({ siteMap: false });
          }
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handleClick = (event: any) => {
    if (event.target.name === "btn3") {
      this.props.router.navigate("/compliance");
    } else {
      this.setState({ loading: true });
      api({
        url: "compliance/addCompliance",
        method: "post",
        data: {
          type: this.state.typeName,
          title: this.state.title,
          body: this.state.body,
        },
        header: true,
      })
        .then((res) => {
          toast.success(res.data.message);
          this.props.router.navigate("/compliance");
          this.setState({ loading: false });
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          this.setState({ loading: false });
        });
    }
  };

  handleUpdate = (event: any) => {
    if (event.target.name === "btn3") {
      this.props.router.navigate("/compliance");
    } else {
      this.setState({ loading: true });

      api({
        url: "compliance/updateCompliance/" + this.state.id,
        method: "patch",
        data: {
          type: this.state.typeName,
          title: this.state.title,
          body: this.state.body,
        },
        header: true,
      })
        .then((res) => {
          toast.success(res.data.message);
          this.props.router.navigate("/compliance");
          this.setState({ loading: false });
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          this.setState({ loading: false });
        });
    }
  };

  getData = () => {
    api({
      url: "compliance/getComplianceByType/" + this.props.router.params.id,
      method: "get",
      data: {},
      header: false,
    })
      .then((res) => {
        console.log("compliance res ::::: ", res);
        let data = res.data.data[0];
        this.setState({
          title: data?.title,
          body: data?.body,
          typeName: data?.type,
          company: data?.company,
          loading: false,
          id: data?._id,
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        this.setState({ loading: false });
      });
  };

  checkPage = () => {
    if (this.props.router.params.id) {
      this.setState({ loading: true });
      this.getData();
    }
  };

  render(): React.ReactNode {
    return (
      <>
        {this.state.loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: "rgba(0,0,0,.3)",
              zIndex: 100000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="loader"></span>
          </div>
        )}
        <Content>
          <ContentHead
            title={
              this.props.router.params.id ? COMPLIANCE_UPDATE : COMPLIANCE_NEW
            }
            showBtn1={true}
            showBtn2={false}
            showBtn3={true}
            btn1Name="btn1"
            btn2Name="btn2"
            btn3Name="btn3"
            btn1Text="Save"
            btn2Text="Save as Draft"
            btn3Text="Cancel"
            btn2type="outlined"
            btn1type="filled"
            btn3type="outlined_del"
            onClickHandler={
              this.props.router.params.id ? this.handleUpdate : this.handleClick
            }
          />
          <ContentBody>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
              }}
            >
              <InputField
                type={"text"}
                isLabel={true}
                lable="Title"
                value={this.state.title}
                onChange={(event: any) => {
                  this.setState({ title: event.target.value });
                }}
                placeholder="Enter title"
              />
              <InputField
                type={"select"}
                isLabel={true}
                lable="Type"
                options={this.handleDropDown()}
                accessText="title"
                accessValue="value"
                value={this.state.typeName}
                onChange={(event: any) => {
                  this.setState({ typeName: event.target.value });
                }}
              />

              {/* <InputField
                type={"text"}
                isLabel={true}
                lable="Company"
                value={this.state.company}
                onChange={(event: any) => {
                  this.setState({ company: event.target.value });
                }}
                placeholder="Enter company name"
              /> */}

              <div style={{ gridColumn: "1/3", width: "100%" }}>
                <TextEditor
                  content={this.state.body}
                  handleChange={(val: any) => {
                    this.setState({ body: val });
                  }}
                />
              </div>
            </div>
          </ContentBody>
        </Content>
      </>
    );
  }
}

export default withRouter(AddCompliance);

// import React from "react";

// const AddCompliance = () => {
//   return <div>index</div>;
// };

// export default AddCompliance;
