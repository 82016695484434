import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

function withRouter(Component: any) {
  const Wrapper = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    return (
      <Component
        router={{ navigate: navigate, location: location, params: params }}
        {...props}
      />
    );
  };

  return Wrapper;
}

export default withRouter;
