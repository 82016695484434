import {
  faBlog,
  faBox,
  faBriefcase,
  faCubes,
  faFileInvoice,
  faPercentage,
  faUserTie,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

import {
  ASPIRANT_PATH,
  ASPIRANT_TITLE,
  BLOG_NEW,
  BLOG_NEW_PATH,
  BLOG_PATH,
  BLOG_TITLE,
  BLOG_UPDATE,
  BLOG_UPDATE_PATH,
  COMPLIANCE_NEW,
  COMPLIANCE_NEW_PATH,
  COMPLIANCE_PATH,
  COMPLIANCE_TITLE,
  COMPLIANCE_UPDATE,
  COMPLIANCE_UPDATE_PATH,
  DISCOUNT_NEW,
  DISCOUNT_NEW_PATH,
  DISCOUNT_PATH,
  DISCOUNT_TITLE,
  DISCOUNT_UPDATE,
  DISCOUNT_UPDATE_PATH,
  JOB_PATH,
  JOB_TITLE,
  ORDER_DETAILS,
  ORDER_DETAILS_PATH,
  ORDER_PATH,
  ORDER_TITLE,
  PRODUCT_NEW,
  PRODUCT_NEW_PATH,
  PRODUCT_PATH,
  PRODUCT_TITLE,
  PRODUCT_UPDATE,
  PRODUCT_UPDATE_PATH,
  USER_DETAILS,
  USER_DETAILS_PATH,
  USER_PATH,
  USER_TITLE,
} from "./constants";

import Blogs from "../app/pages/blog";
import Products from "../app/pages/products";
import Orders from "../app/pages/orders";
import Jobs from "../app/pages/jobs";
import Aspirants from "../app/pages/aspirants";
import Users from "../app/pages/users";
import AddUpdateBlog from "../app/pages/blog/addUpdateBlog";
import AddUpdateProduct from "../app/pages/products/addUpdateProduct";
import compliance from "../app/pages/compliance";
import AddCompliance from "../app/pages/compliance/Add";
import UpdateCompliance from "../app/pages/compliance/Update";
import Discounts from "../app/pages/discounts";
import AddUpdateDiscounts from "../app/pages/discounts/AddUpdateDiscounts";
import OrderDetail from "../app/pages/orders/OrderDetail";
import UserDetails from "../app/pages/users/UserDetails";

export const delete_text =
  "Do you really want to delete this record? This process cannot be undone.";

export const sideBar = [
  {
    title: PRODUCT_TITLE,
    path: PRODUCT_PATH,
    icon: faBox,
    element: Products,
    show: true,
  },
  {
    title: PRODUCT_NEW,
    path: PRODUCT_NEW_PATH,
    element: AddUpdateProduct,
    show: false,
  },
  {
    title: PRODUCT_UPDATE,
    path: PRODUCT_UPDATE_PATH,
    element: AddUpdateProduct,
    show: false,
  },
  {
    title: ORDER_TITLE,
    path: ORDER_PATH,
    icon: faCubes,
    element: Orders,
    show: true,
  },
  {
    title: ORDER_DETAILS,
    path: ORDER_DETAILS_PATH,
    element: OrderDetail,
    show: false,
  },
  {
    title: DISCOUNT_TITLE,
    path: DISCOUNT_PATH,
    icon: faPercentage,
    element: Discounts,
    show: true,
  },
  {
    title: DISCOUNT_NEW,
    path: DISCOUNT_NEW_PATH,
    element: AddUpdateDiscounts,
    show: false,
  },
  {
    title: DISCOUNT_UPDATE,
    path: DISCOUNT_UPDATE_PATH,
    element: AddUpdateDiscounts,
    show: false,
  },
  {
    title: USER_TITLE,
    path: USER_PATH,
    icon: faUsers,
    element: Users,
    show: true,
  },
  {
    title: USER_DETAILS,
    path: USER_DETAILS_PATH,
    element: UserDetails,
    show: false,
  },
  {
    title: BLOG_TITLE,
    path: BLOG_PATH,
    icon: faBlog,
    element: Blogs,
    show: true,
  },

  {
    title: BLOG_NEW,
    path: BLOG_NEW_PATH,
    element: AddUpdateBlog,
    show: false,
  },
  {
    title: BLOG_UPDATE,
    path: BLOG_UPDATE_PATH,
    element: AddUpdateBlog,
    show: false,
  },
  {
    title: COMPLIANCE_TITLE,
    path: COMPLIANCE_PATH,
    icon: faFileInvoice,
    element: compliance,
    show: true,
  },
  {
    title: COMPLIANCE_NEW,
    path: COMPLIANCE_NEW_PATH,
    element: AddCompliance,
    show: false,
  },
  {
    title: COMPLIANCE_UPDATE,
    path: COMPLIANCE_UPDATE_PATH,
    element: UpdateCompliance,
    show: false,
  },
];

export const users_table_head = [
  { title: "S.NO" },
  { title: "Name" },
  { title: "Email" },
  { title: "Mobile" },
  { title: "" },
];

export const users_table_body_key = [
  "fullName",
  "email",
  "mobile.number",
  "action:delete",
];

export const product_table_head = [
  { title: "S.NO", filter: false },
  { title: "Product Code", filter: false },
  { title: "Product Name", filter: false },
  { title: "Category", value: "Category", filter: true },
  { title: "Collection", value: "Collection", filter: true },
  { title: "Color", value: "Color", filter: true },
  { title: "Status", value: "Status", filter: true },
  { title: "Stock", filter: false },
  { title: "", filter: false },
];

export const product_access_keys = [
  "productCode",
  "productName",
  "productSubGroup3",
  "productCollection",
  "color",
  "status",
  "stockInHand",
  "action:delete",
];

export const blog_table_head = [
  { title: "S.NO" },
  { title: "Blog Title" },
  { title: "Content Type" },
  { title: "" },
];

export const blog_keys = ["title", "type", "action:delete"];

export const compliance_table_head = [
  { title: "S.NO" },
  { title: "Title" },
  { title: "Type" },
  { title: "" },
];

export const compliance_body_keys = ["title", "type", "action:delete"];

export const discount_table_head = [
  { title: "S.NO" },
  { title: "Discount Title" },
  { title: "" },
];

export const discount_table_body_keys = ["discountTitle", "action:delete"];

export const orders_table_head = [
  { title: "S.NO" },
  { title: "Order Id" },
  { title: "Total" },
  { title: "Payment Type" },
  { title: "Payment Status" },
  { title: "Fulfillment Status" },
];

export const orders_table_body_key = [
  "orderRefId",
  "total",
  "paymentType",
  "paymentStatus",
  "fulfilmentStatus",
];
