import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import img1 from "../../assets/daydream.webp";
import api from "../../api";
import { SideBarContext } from "../../store/sidebar";
import styles from "../../styles/login.module.css";
import { ReactComponent as Logo } from "../../assets/logo.svg";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailSubmited, setEmailSubmited] = useState(false);
  const [otp, setOtp] = useState("");
  const [token, setToken] = useState("");
  const [showError, setShowError] = useState("granted");
  const context: any = useContext(SideBarContext);
  const [resendOtpLoading, setResendOtpLoading] = useState(false);
  const [counter, setCounter] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  // when user clicks on sign-in
  const loginUser = () => {
    setLoading(true);
    api({
      method: "post",
      url: "auth/login",
      data: { email, password: otp },
      header: false,
    })
      .then((res) => {
        localStorage.setItem("oren_auth_token", res.data.token);
        window.location.reload();
      })
      .catch((err) => {
        console.log("Error : ", err);
        toast.error(err.response.data.message);
        // showError();
        setLoading(false);
      });
  };

  // this is used for handle sign-in
  const handleLogin = () => {
    if (!email) {
      toast.error("Email is required.");
    } else if (!otp) {
      toast.error("Password is required.");
    } else {
      loginUser();
    }
  };

  // this is used for handle sign-up
  const handleSignup = () => {
    if (!otp) {
      toast.error("Password is required.");
    } else if (!email) {
      toast.error("Email is required.");
    } else if (!firstName) {
      toast.error("First name is required.");
    } else if (!lastName) {
      toast.error("Last name is required.");
    } else {
      // make a call to verify otp

      api({
        method: "post",
        url: "auth/register",
        data: { firstName, lastName, email, password: otp },
        header: false,
      })
        .then((res: any) => {
          toast.success(
            "Verification mail has been sent to your email address."
          );
        })
        .catch((err) => {
          console.log("Error : ", err);
          toast.error(err.response.data.message);
        });
    }
  };

  const resendOtp = () => {
    setResendOtpLoading(true);
    api({
      method: "post",
      url: "sendotp",
      header: false,
      data: { email },
    })
      .then((res) => {
        if (res.data.message === "Email sent successfully.") {
          toast.success(
            "Your OTP has been reissued and sent to your designated email address. Kindly ensure to check both your inbox and spam folder.",
            { duration: 10000 }
          );
          setToken(res.data.token);
          // localStorage.setItem("token-BO-ADMIN", res.data.token);
          setEmailSubmited(true);
          setResendOtpLoading(false);
          setCounter(60);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        // showError();
        setResendOtpLoading(false);
      });
  };

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <div className={styles.container}>
      <div className={`${styles.main}`}>
        <div
          className={`${styles.slide} ${emailSubmited ? styles.forward : ""}`}
        >
          {showError === "granted" && (
            <>
              {/* modal container 1 */}
              <div className={styles.modal_container}>
                <div className={styles.modal}>
                  <h1>Admin Login</h1>
                  <div className={styles.fields_container}>
                    <div className={styles.label_and_field}>
                      <span>Email</span>
                      <input
                        type="email"
                        placeholder="Enter email address"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <div style={{ marginTop: "20px" }}>
                        <span>Password</span>
                        <input
                          type="password"
                          placeholder="Enter Your Password"
                          value={otp}
                          onChange={(e) => {
                            setOtp(e.target.value);
                          }}
                        />
                      </div>
                      <button onClick={handleLogin} disabled={loading}>
                        {loading ? (
                          <span className={styles.loader}></span>
                        ) : (
                          <>Sign in</>
                        )}
                      </button>
                      {/* <p
                        style={{
                          textAlign: "center",
                          marginTop: "10px",
                          textDecoration: "underline",
                          cursor: "pointer",
                          width: "fit-content",
                          marginInline: "auto",
                        }}
                        onClick={() => {
                          setEmail("");
                          setOtp("");
                          setEmailSubmited(true);
                        }}
                      >
                        Sign up
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* modal container 2 */}
              <div className={styles.modal_container_2}>
                <div className={styles.modal}>
                  <h1>Admin Sign-up</h1>
                  <div className={styles.fields_container}>
                    <div className={styles.label_and_field}>
                      <div>
                        <span>First Name</span>
                        <input
                          type="text"
                          value={firstName}
                          placeholder="Enter your first name"
                          onChange={(e: any) => setFirstName(e.target.value)}
                        />
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <span>Last Name</span>
                        <input
                          type="text"
                          placeholder="Enter your last name"
                          value={lastName}
                          onChange={(e: any) => setLastName(e.target.value)}
                        />
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <span>Email</span>
                        <input
                          type="email"
                          placeholder="Enter your email address"
                          value={email}
                          onChange={(e: any) => setEmail(e.target.value)}
                        />
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <span>Password</span>
                        <input
                          type="password"
                          placeholder="Enter your password"
                          value={otp}
                          onChange={(e) => {
                            setOtp(e.target.value);
                          }}
                        />
                      </div>
                      <button
                        onClick={() => {
                          handleSignup();
                        }}
                      >
                        {loading ? (
                          <span className={styles.loader}></span>
                        ) : (
                          <>Sign up</>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {showError === "paused" ||
            (showError === "suspended" && (
              <div className={styles.modal_container}>
                <div className={styles.modal}>
                  <h1>Error for {showError}</h1>
                </div>
              </div>
            ))}
        </div>
        {/* back button */}
        <div className={styles.arrow_container}>
          <div
            className={`${styles.back_arrow} ${
              emailSubmited ? styles.show_arrow : ""
            }`}
            onClick={() => {
              setEmailSubmited(false);
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
        </div>
      </div>
      <div
        className={styles.carousel}
        style={{
          width: "100%",
          height: "100%",
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            background: "rgba(0,0,0,.2)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Logo />
        </div>
      </div>
    </div>
  );
};

export default Login;

// access
