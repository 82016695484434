import { useState } from "react";
import styles from "../../styles/toggleSwitch.module.css";

type Props = {
  value: boolean;
  onHoverTxt?: string;
  onClick: () => void;
};

const ToggleSwitch = (props: Props) => {
  return (
    <div
      className={styles.container}
      style={{ backgroundColor: props.value ? "#cdffda" : "" }}
      onClick={props.onClick}
    >
      <div
        className={`${styles.circle} ${props.value ? styles.circle_on : ""}`}
      ></div>
      {props.onHoverTxt && <p>{props.onHoverTxt}</p>}
    </div>
  );
};

export default ToggleSwitch;
