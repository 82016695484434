import React from "react";
import axios from "axios";
import withRouter from "../../../components/withRouter";
import { toast } from "react-hot-toast";
import Modal from "../../../components/Modal";
import Content from "../../../components/Content/Content";
import ContentHead from "../../../components/Content/ContentHead";
import ContentBody from "../../../components/Content/ContentBody";
import Table from "../../../components/Table";
import { COMPLIANCE_TITLE } from "../../../utils/constants";
import {
  compliance_body_keys,
  compliance_table_head,
} from "../../../utils/data";
import api from "../../../api";

class Compliance extends React.Component<any, any> {
  state = {
    data: [],
    loading: true,
    showbtn: false,
    modal: false,
    inFocus: "",
  };

  componentDidMount(): void {
    this.getData();
  }

  getData = () => {
    api({
      method: "get",
      url: "compliance/getAllCompliance",
      data: {},
      header: false,
    })
      .then((res) => {
        this.setState({ data: res.data.data.compliance, loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handleAction = (val: string, id: string) => {
    if (val === "delete") {
      this.setState({ modal: true, inFocus: id });
    }

    if (val === "archive") {
      const promise = new Promise((resolve, reject) => {
        axios({
          method: "patch",
          data: { status: "draft" },
          url: `https://backend.cftcommerce.com/api/compliances/${id}`,
        })
          .then((res) => {
            resolve(true);
          })
          .catch(() => {
            reject();
          });
      });

      toast
        .promise(
          promise,
          {
            loading: "Archiving...",
            success: "Archived Successfully.",
            error: "Error when archiving the entry.",
          },
          { success: { duration: 2000 }, error: { duration: 2000 } }
        )
        .then(() => {
          this.getData();
        });
    }

    if (val === "unarchive") {
      const promise = new Promise((resolve, reject) => {
        axios({
          method: "patch",
          data: { status: "live" },
          url: `https://backend.cftcommerce.com/api/compliances/${id}`,
        })
          .then((res) => {
            resolve(true);
          })
          .catch(() => {
            reject();
          });
      });

      toast
        .promise(
          promise,
          {
            loading: "UnArchiving...",
            success: "UnArchived Successfully.",
            error: "Error when unarchiving the entry.",
          },
          { success: { duration: 2000 }, error: { duration: 2000 } }
        )
        .then(() => {
          this.getData();
        });
    }
  };

  checkForAddBtn = () => {
    if (localStorage.getItem("ecommerce") === "true") {
      if (this.state.data.length === 5) {
        return false;
      }

      return this.state.data.filter((val: any) => {
        if (
          val.typeName === "terms" ||
          val.typeName === "privacy" ||
          val.typeName === "returnPolicy" ||
          val.typeName === "cancellationPolicy" ||
          val.typeName === "dataPolicy"
        ) {
          return true;
        }
        return false;
      }).length === 5
        ? false
        : true;
    } else if (localStorage.getItem("service") === "true") {
      if (this.state.data.length === 3) {
        return false;
      }

      return this.state.data.filter((val: any) => {
        if (
          val.typeName === "terms" ||
          val.typeName === "privacy" ||
          val.typeName === "cancellationPolicy"
        ) {
          return true;
        }
        return false;
      }).length === 3
        ? false
        : true;
    } else if (localStorage.getItem("blog") === "true") {
      if (this.state.data.length === 2) {
        return false;
      }

      return this.state.data.filter((val: any) => {
        if (val.typeName === "terms" || val.typeName === "privacy") {
          return true;
        }
        return false;
      }).length === 2
        ? false
        : true;
    }
    return false;
  };

  delete = () => {
    this.setState({ modal: false });
    const promise = new Promise((resolve, reject) => {
      api({
        url: `compliance/deleteCompliance/${this.state.inFocus}`,
        method: "delete",
        data: {},
        header: true,
      })
        .then((res) => {
          this.setState({ inFocus: "" });
          resolve(true);
        })
        .catch((err) => {
          reject();
        });
    });

    toast
      .promise(
        promise,
        {
          loading: "Deleting...",
          success: "Deleted Successfully.",
          error: "Error when deleting the entry.",
        },
        { success: { duration: 2000 }, error: { duration: 2000 } }
      )
      .then(() => {
        this.getData();
      });
  };

  render(): React.ReactNode {
    return (
      <>
        <Modal
          title={"Delete"}
          description={
            "Do you really want to delete this record? This process cannot be undone."
          }
          state={this.state.modal}
          onBtn1Click={() => this.setState({ modal: false, inFocus: "" })}
          onBtn2Click={this.delete}
        />
        {this.state.loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: "rgba(0,0,0,.3)",
              zIndex: 100000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="loader"></span>
          </div>
        )}
        <Content>
          <ContentHead
            title={COMPLIANCE_TITLE}
            showBtn1={true}
            btn1Name="btn1"
            btn1Text=" Add Compliance"
            btn1type="filled"
            isIcon={true}
            onClickHandler={() => this.props.router.navigate("/compliance/new")}
          />
          <ContentBody style={{ padding: 0 }}>
            <>
              <Table
                head={compliance_table_head}
                body={this.state.data}
                body_keys={compliance_body_keys}
                number={true}
                onActionClick={this.handleAction}
                onRowClick={(val: any) => {
                  this.props.router.navigate(`/compliance/update/${val.type}`);
                }}
              />
            </>
          </ContentBody>
        </Content>
      </>
    );
  }
}

export default withRouter(Compliance);
