import React from "react";
import AppLayout from "../components/AppLayout";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import api from "../api";
import { toast } from "react-hot-toast";
import Login from "../components/Login";
import VerifyUser from "../components/VerifyUser";

class App extends React.Component {
  render(): React.ReactNode {
    return (
      <>
        <AppLayout>
          <Outlet />
        </AppLayout>
      </>
    );
  }
}

export default App;
