import ReactDOM from "react-dom/client";
import "./styles/global.css";
import Router from "./app/router";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <Toaster />
    <Router />
  </>
);
