import styles from "../../styles/inputField.module.css";
import { ReactComponent as Down } from "../../assets/down.svg";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

type Props = {
  isLabel: boolean;
  value: string;
  lable?: string;
  type: string;
  isRequired?: boolean;
  placeholder?: string;
  style?: any;
  name?: string;
  onChange: any;
  options?: any;
  accessText?: string | undefined;
  accessValue?: string | undefined;
  onKeyPress?: any;
  error?: boolean;
  errorText?: string;
  sideIcon?: boolean;
};

const InputField = (props: Props) => {
  const selectRef: any = useRef();

  return (
    <>
      <div
        className={styles.field_contianer}
        style={props.style ? { ...props.style } : {}}
      >
        {props.isLabel && (
          <p>
            {props.lable} {props.isRequired && "*"}
          </p>
        )}
        {props.type === "select" ? (
          <div
            className={`${styles.select_container} ${
              props.error ? styles.error : ""
            }`}
          >
            <select
              ref={selectRef}
              value={props.value}
              onChange={props.onChange}
              name={props?.name ? props.name : ""}
            >
              <option value="" disabled selected>
                {props.placeholder || "Select your option"}
              </option>
              {props.options.map((val: any, index: any) => {
                return (
                  <>
                    {props.name === "colorVariant" ||
                    props.name === "sizeVariant" ? (
                      <option
                        key={index}
                        value={
                          val._id +
                          ":" +
                          val.SKU +
                          ":" +
                          val.color +
                          ":" +
                          val.size +
                          ":" +
                          val.imageUrl +
                          ":" +
                          val.slug
                        }
                        disabled={val?.disabled ? val.disabled : false}
                      >
                        {val.SKU}
                      </option>
                    ) : (
                      <option
                        key={index}
                        value={val[props?.accessValue!]}
                        disabled={val?.disabled ? val.disabled : false}
                      >
                        {val[props?.accessText!]}
                      </option>
                    )}
                  </>
                );
              })}
            </select>
            <Down
              onClick={() => {
                if (selectRef.current) {
                  selectRef.current?.click();
                }
              }}
            />
          </div>
        ) : props.type === "textarea" ? (
          <textarea
            className={styles.textarea}
            value={props.value}
            onChange={props.onChange}
            placeholder={props.placeholder}
          ></textarea>
        ) : (
          <div className={styles.input_container}>
            <input
              className={`${props?.error ? styles.error : ""}`}
              type={props.type}
              placeholder={props.placeholder}
              name={props?.name ? props.name : ""}
              value={props.value}
              onChange={props.onChange}
              onKeyPress={props?.onKeyPress}
            ></input>
            {props?.sideIcon && <div className={styles.side_icon}>%</div>}
          </div>
        )}
        {/* error text */}
        {props?.error && (
          <div className={styles.error_text}>
            <FontAwesomeIcon icon={faCircleExclamation} color="#C4270D" />
            <p>{props.errorText}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default InputField;
