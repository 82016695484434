import React, { useEffect, useState } from "react";
import Content from "../../../components/Content/Content";
import ContentHead from "../../../components/Content/ContentHead";
import {
  DISCOUNT_TITLE,
  DISCOUNT_UPDATE,
  TOAST_DELETE_ERROR,
  TOAST_DELETE_LOADING,
  TOAST_DELETE_SUCCESS,
} from "../../../utils/constants";
import ContentBody from "../../../components/Content/ContentBody";
import Table from "../../../components/Table";
import {
  delete_text,
  discount_table_body_keys,
  discount_table_head,
} from "../../../utils/data";
import Modal from "../../../components/Modal";
import Loading from "../../../components/Loading";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import api from "../../../api";

// variables which do not effect the ui state
let inFocus = "";

const Discounts = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api({
      url: "discount",
      method: "get",
      header: true,
    })
      .then((res: any) => {
        setData(res.data.results);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        toast.error(err.response.data.message);
        setData([]);
      });
  };

  const handleDelete = () => {
    setModal(false);
    toast.promise(
      new Promise((resolve, reject) => {
        api({
          url: "discount/" + inFocus,
          method: "DELETE",
          header: true,
        })
          .then(() => {
            getData();
            resolve(true);
          })
          .catch((err) => {
            reject(false);
          });
      }),
      {
        loading: TOAST_DELETE_LOADING,
        success: TOAST_DELETE_SUCCESS,
        error: TOAST_DELETE_ERROR,
      },
    );
  };

  const handleRowClick = (val: any) => {
    navigate("/discounts/update/" + val._id);
  };

  const handleActionClick = (_: string, id: string) => {
    setModal(true);
    inFocus = id;
  };

  const closeModal = () => {
    setModal(false);
    inFocus = "";
  };

  return (
    <>
      <Modal
        title={"Delete"}
        state={modal}
        description={delete_text}
        onBtn1Click={closeModal}
        onBtn2Click={handleDelete}
      />
      <Loading state={loading} />
      <Content>
        <ContentHead
          title={DISCOUNT_TITLE}
          showBtn1={true}
          btn1Text="Add New Discount"
          btn1type="filled"
          btn1Name="btn1"
          onClickHandler={() => navigate("/discounts/new")}
        />
        <ContentBody style={{ padding: 0 }}>
          <Table
            head={discount_table_head}
            body={data}
            body_keys={discount_table_body_keys}
            number={true}
            onActionClick={handleActionClick}
            onRowClick={handleRowClick}
          ></Table>
        </ContentBody>
      </Content>
    </>
  );
};

export default Discounts;
