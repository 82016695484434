import React, { useEffect, useState } from "react";
import Content from "../../../components/Content/Content";
import ContentHead from "../../../components/Content/ContentHead";
import ContentBody from "../../../components/Content/ContentBody";
import { PRODUCT_NEW_PATH, PRODUCT_TITLE } from "../../../utils/constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import Table from "../../../components/Table";
import api from "../../../api";
import {
  delete_text,
  product_access_keys,
  product_table_head,
} from "../../../utils/data";
import Modal from "../../../components/Modal";
import { toast } from "react-hot-toast";
import * as XLSX from "xlsx";
import Loading from "../../../components/Loading";
import styles from "../../../styles/products.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  MapCollectionToId,
  MapCollectionToName,
  getUnique,
} from "../../../utils/utils";
import { useQuery } from "../../../utils/getQuery";
import { useLocation } from "react-router-dom";

const Products = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData]: any = useState([]);
  const [modal, setModal] = useState(false);
  const [inFocus, setInFocus] = useState("");
  const [filterData, setFilterData] = useState<any>();
  const [filter, setFilter]: any = useState({});
  const [selectedFilter, setSelectedFilter]: any = useState({});
  const navigate = useNavigate();
  const [filterModal, setFilterModal] = useState(false);
  const [search, setSearch] = useState("");
  const location = useLocation();
  const query_data: string = useQuery();
  console.log(query_data);

  useEffect(() => {
    getData();
  }, [location.search]);

  useEffect(() => {
    getUniqueFilterData();
  }, []);

  const getUniqueFilterData = async () => {
    try {
      const response = await api({
        url: "products/getUnique",
        method: "get",
        header: false,
      });
      console.log(response.data);
      setFilterData({
        Category: response.data.productSubGroup3.filter((val: string) => {
          return val.trim() !== "";
        }),
        Collection: [
          "Ethereal",
          "Daydream",
          "Serenity",
          "Grace",
          "Gourmet",
          "Terra Serve",
          "Earth Serve",
        ],
        Color: response.data.color,
        productSubGroup3: response.data.productSubGroup3,
        productSubGroup1: response.data.productSubGroup1,
        Status: ["active", "inactive"],
      });
    } catch (error) {}
  };

  const getData = () => {
    setLoading(true);
    api({
      url: `products/filter?${query_data}`,
      method: "get",
      data: {},
      header: false,
    })
      .then((res: any) => {
        // @ts-ignore
        console.log(res.data.data);
        setData([...res.data.data]);
        setLoading(false);
        // if (
        //   window.location.search.includes("Category") ||
        //   window.location.search.includes("Collection")
        // ) {
        //   resetUniqueValueOfFilter([...res.data.data]);
        // }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const resetUniqueValueOfFilter = () => {
    let temp: string[] = [];
    let temp2: any = [];
    let cat: any = [];
    data.map((val: any) => {
      if (window.location.search.includes("Category")) {
        if (temp.indexOf(val.color) === -1) {
          temp.push(val.color);
        }
        if (
          temp2.indexOf(MapCollectionToName(val.productCollection._id)) === -1
        ) {
          temp2.push(MapCollectionToName(val.productCollection._id));
        }
        setFilterData({
          ...filterData,
          Color: temp,
          Collection: temp2,
          // Category: cat,
        });
      } else if (window.location.search.includes("Collection")) {
        if (temp.indexOf(val.color) === -1) {
          temp.push(val.color);
        }
        if (cat.indexOf(val.productSubGroup3) === -1) {
          cat.push(val.productSubGroup3);
        }
        setFilterData({
          ...filterData,
          Color: temp,
          // Collection: temp2,
          Category: cat,
        });
      } else if (window.location.search.includes("Color")) {
        if (
          temp2.indexOf(MapCollectionToName(val.productCollection._id)) === -1
        ) {
          temp2.push(MapCollectionToName(val.productCollection._id));
        }
        if (cat.indexOf(val.productSubGroup3) === -1) {
          cat.push(val.productSubGroup3);
        }
        setFilterData({
          ...filterData,
          // Color: temp,
          Collection: temp2,
          Category: cat,
        });
      } else {
        if (temp.indexOf(val.color) === -1) {
          temp.push(val.color);
        }
        if (
          temp2.indexOf(MapCollectionToName(val.productCollection._id)) === -1
        ) {
          temp2.push(MapCollectionToName(val.productCollection._id));
        }
        if (cat.indexOf(val.productSubGroup3) === -1) {
          cat.push(val.productSubGroup3);
        }
        setFilterData({
          ...filterData,
          Color: temp,
          Collection: temp2,
          // Category: cat,
        });
      }
    });
  };

  useEffect(() => {
    resetUniqueValueOfFilter();
  }, [data]);

  const clickHandler = (event: any) => {
    let btn = event.target.name;

    if (btn === "btn1") {
      navigate(PRODUCT_NEW_PATH);
    } else if (btn === "btn2") {
      bulkUpload();
    }
  };

  const handledelete = () => {
    setModal(false);
    toast.promise(
      new Promise((resolve, reject) => {
        api({
          url: "products/deleteProduct/" + inFocus,
          method: "delete",
          data: {},
          header: true,
        })
          .then((res: any) => {
            getData();
            resolve(true);
          })
          .catch((err: any) => {
            reject(false);
          });
      }),
      {
        loading: "Deleting...",
        success: "Deleted successfully",
        error: "Error while deleting",
      }
    );
  };

  const bulkUpload = () => {
    // create a input element which accepts excel files and then when file is selected remove it
    // and then send the file to the backend

    // @ts-ignore
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx";
    input.click();
    input.onchange = (e: any) => {
      setLoading(true);

      let file = e.target.files[0];

      // now read this excel file and covert it into json and then send it to the backend
      // @ts-ignore
      let reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (e: any) => {
        let data = e.target.result;
        let workbook = XLSX.read(data, { type: "binary" });
        let sheet_name_list = workbook.SheetNames;
        let xlData = XLSX.utils.sheet_to_json(
          workbook.Sheets[sheet_name_list[0]]
        );
        console.log(xlData);

        if (xlData.length === 0) {
          toast.error("No Data Found");
          return;
        }

        xlData = xlData.map((val: any) => {
          return {
            alternateBrand: val?.alternateBrand
              ? String(val?.alternateBrand)
              : "",
            alternateModelNo: val?.alternateModelNo
              ? String(val?.alternateModelNo)
              : "",
            batchNo: val?.batchNo
              ? typeof val?.batchNo === "boolean"
                ? val?.batchNo
                : val?.batchNo.toLowerCase() === "true"
                ? true
                : false
              : false,
            boxHeight: Number(val?.boxHeight),
            boxLength: Number(val?.boxLength),
            boxWeight: Number(val?.boxWeight),
            boxWidth: Number(val?.boxWidth),
            brandName: val?.brandName ? String(val?.brandName) : "",
            capacity: Number(val?.capacity),
            color: val?.color ? String(val?.color) : "",
            countryOfOrigin: val?.countryOfOrigin
              ? String(val?.countryOfOrigin)
              : "",
            countryOfShipment: val?.countryOfShipment
              ? String(val?.countryOfShipment)
              : "",
            depth: Number(val?.depth),
            diameter: Number(val?.diameter),
            discount: Number(val?.discount),
            dutyStructure: val?.dutyStructure ? String(val?.dutyStructure) : "",
            gstRate: val?.gstRate ? String(val?.gstRate) : "",
            gstRelevant: val?.gstRelevant
              ? typeof val?.gstRelevant === "boolean"
                ? val?.gstRelevant
                : val?.gstRelevant.toLowerCase() === "true"
                ? true
                : false
              : false,
            height: Number(val?.height),
            hsnCode: Number(val?.hsnCode),
            internalBrand: val?.internalBrand ? String(val?.internalBrand) : "",
            inventoryLevelMaximum: val?.inventoryLevelMaximum
              ? String(val?.inventoryLevelMaximum)
              : "",
            inventoryLevelMinimum: val?.inventoryLevelMinimum
              ? String(val?.inventoryLevelMinimum)
              : "",
            length: Number(val?.length),
            listPrice: Number(val?.listPrice),
            madeUpMaterial: val?.madeUpMaterial
              ? String(val?.madeUpMaterial)
              : "",
            minimumOrderQuantity: Number(val?.minimumOrderQuantity),
            netPrice: Number(val?.netPrice),
            orderMultiple: Number(val?.orderMultiple),
            palletPrice: Number(val?.palletPrice),
            palletQuantity: Number(val?.palletQuantity),
            power: Number(val?.power),
            priceApplicableFrom: new Date(val?.priceApplicableFrom),
            priceApplicableTo: new Date(val?.priceApplicableTo),
            productCategory: val?.productCategory
              ? String(val?.productCategory)
              : "",
            productCode: val?.productCode ? String(val?.productCode) : "",
            productCodeAlias1: val?.productCodeAlias1
              ? String(val?.productCodeAlias1)
              : "",
            productCodeAlias2: val?.productCodeAlias2
              ? String(val?.productCodeAlias2)
              : "",
            productCodeAlias3: val?.productCodeAlias3
              ? String(val?.productCodeAlias3)
              : "",
            productCodeAlias4: val?.productCodeAlias4
              ? String(val?.productCodeAlias4)
              : "",
            productCollection: val?.productCollection,
            productName: val?.productName ? String(val?.productName) : "",
            productSpecLong: val?.productSpecLong
              ? String(val?.productSpecLong)
              : "",
            productSpecShort: val?.productSpecShort
              ? String(val?.productSpecShort)
              : "",
            productSubGroup1: val?.productSubGroup1
              ? String(val?.productSubGroup1)
              : "",
            productSubGroup2: val?.productSubGroup2
              ? String(val?.productSubGroup2)
              : "",
            productSubGroup3: val?.productSubGroup3
              ? String(val?.productSubGroup3)
              : "",
            productSubGroup4: val?.productSubGroup4
              ? String(val?.productSubGroup4)
              : "",
            productSubGroup5: val?.productSubGroup5
              ? String(val?.productSubGroup5)
              : "",
            productType: val?.productType ? String(val?.productType) : "",
            purchaseCasePackQuantity: Number(val?.purchaseCasePackQuantity),
            purchaseCurrency: val?.purchaseCurrency
              ? String(val?.purchaseCurrency)
              : "",
            purchaseLeadTime: new Date(val?.purchaseLeadTime),
            purchaseMasterCartonPackQuantity: Number(
              val?.purchaseMasterCartonPackQuantity
            ),
            purchaseTransitTime: new Date(val?.purchaseTransitTime),
            reorderLevel: val?.reorderLevel ? String(val?.reorderLevel) : "",
            safetyStock: Number(val?.safetyStock),
            salesCasePackQuantity: Number(val?.salesCasePackQuantity),
            salesMasterCartonPackQuantity: Number(
              val?.salesMasterCartonPackQuantity
            ),
            setQty: Number(val?.setQty),
            sku: val?.sku ? String(val?.sku) : "",
            status: val?.status ? String(val?.status) : "",
            stockInHand: Number(val?.stockInHand),
            subMadeUpMaterial: val?.subMadeUpMaterial
              ? String(val?.subMadeUpMaterial)
              : "",
            supplierId: val?.supplierId ? String(val?.supplierId) : "",
            supplierProductCode: val?.supplierProductCode
              ? String(val?.supplierProductCode)
              : "",
            thickness: Number(val?.thickness),
            voltage: Number(val?.voltage),
            weight: Number(val?.weight),

            width: Number(val?.width),
          };
        });

        // now send this data to the backend
        api({
          url: "products/bulkUpload",
          method: "post",
          data: xlData,
          header: false,
        })
          .then((res: any) => {
            toast.success("Bulk upload successful");
            getData();
            setLoading(false);
          })
          .catch((err: any) => {
            console.log(err);
            toast.error("Error while uploading");
            setLoading(false);
          });
      };
    };
  };

  useEffect(() => {
    if (search !== "") {
      setLoading(true);

      setTimeout(() => {
        // console.log(search);
        api({
          url: "products/search?keyword=" + search,
          method: "get",
          header: false,
        })
          .then((res: any) => {
            console.log("products data ::: ", res.data.results[0]);
            setData([...res.data.results[0]]);
            setLoading(false);
          })
          .catch((err: any) => {
            setData([]);
            console.log(err);
            setLoading(false);
          });
      }, 2000);
    }
  }, [search]);

  const updatedAllProductToActive = async () => {
    for (let i = 0; i < data.length; i++) {
      const response = await api({
        url: "products/updateProduct/" + data[i]?._id,
        method: "PATCH",
        data: {
          status: "active",
        },
        header: true,
      });
    }
  };

  return (
    <>
      <Modal
        title={"Delete"}
        description={delete_text}
        onBtn1Click={() => {
          setModal(false);
          setInFocus("");
        }}
        onBtn2Click={handledelete}
        state={modal}
      />
      <Loading state={loading} />
      <Content>
        <ContentHead
          title={PRODUCT_TITLE}
          showBtn1={true}
          showBtn2={true}
          btn1Name="btn1"
          btn2Name="btn2"
          btn1Text=" Add Products"
          btn2Text="Bulk Upload"
          btn1type="filled"
          btn2type="outlined"
          // isIcon={true}
          onClickHandler={clickHandler}
          customElement={
            <div className={styles.custom_element}>
              <div className={styles.search_product_container}>
                <FontAwesomeIcon icon={faSearch} color="grey" />
                <input
                  type="text"
                  placeholder="Search Product"
                  value={search}
                  onChange={(e: any) => setSearch(e.target.value)}
                />
              </div>
              {/* <div
                className={styles.filter_container}
                style={{ cursor: "pointer" }}
                onClick={() => setFilterModal(true)}
              >
                <FontAwesomeIcon
                  icon={faFilter}
                  color="grey"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setFilterModal(true);
                  }}
                />
                Filter
                {filterModal && (
                  <div className={styles.filter_elements_container}>
                    <div className={styles.filter_modal_head}>
                      <h3>Filter</h3>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedFilter((prev: any) => {
                            let temp: any = { ...prev };
                            Object.keys(temp).forEach((x) => {
                              temp[x] = [];
                            });
                            return temp;
                          });
                        }}
                      >
                        Clear
                      </p>
                    </div>
                    <ul className={styles.filter_level_one_list}>
                      <li>
                        <h4>Categories</h4>
                        <ul className={styles.filter_level_two_list}>
                          {filter["categories"]
                            .filter((v: any) => v)
                            .map((m_val: any, iindex: any) => {
                              return (
                                <li
                                  key={iindex}
                                  onClick={() => {
                                    let val = "categories";
                                    let temp: any = selectedFilter;
                                    if (
                                      temp["categories"].indexOf(m_val) !== -1
                                    ) {
                                      temp["categories"].splice(
                                        temp[val].indexOf(m_val),
                                        1
                                      );
                                      setSelectedFilter({ ...temp });
                                    } else {
                                      temp[val].push(m_val);
                                      setSelectedFilter({ ...temp });
                                    }
                                  }}
                                  style={
                                    selectedFilter["categories"].indexOf(
                                      m_val
                                    ) !== -1
                                      ? {
                                          background: "black",
                                          color: "white",
                                        }
                                      : {}
                                  }
                                >
                                  {m_val}
                                </li>
                              );
                            })}
                        </ul>
                      </li>
                      <li>
                        <h4>Product Type</h4>
                        <ul className={styles.filter_level_two_list}>
                          {filter["productSubGroup3"]
                            .filter((v: any) => v)
                            .map((m_val: any, iindex: any) => {
                              return (
                                <li
                                  key={iindex}
                                  onClick={() => {
                                    let val = "productSubGroup3";
                                    let temp: any = selectedFilter;
                                    if (temp[val].indexOf(m_val) !== -1) {
                                      temp[val].splice(
                                        temp[val].indexOf(m_val),
                                        1
                                      );
                                      setSelectedFilter({ ...temp });
                                    } else {
                                      temp[val].push(m_val);
                                      setSelectedFilter({ ...temp });
                                    }
                                  }}
                                  style={
                                    selectedFilter["productSubGroup3"].indexOf(
                                      m_val
                                    ) !== -1
                                      ? {
                                          background: "black",
                                          color: "white",
                                        }
                                      : {}
                                  }
                                >
                                  {m_val}
                                </li>
                              );
                            })}
                        </ul>
                      </li>
                      <li>
                        <h4>Colors</h4>
                        <ul className={styles.filter_level_two_list}>
                          {filter["color"]
                            .filter((v: any) => v)
                            .map((m_val: any, iindex: any) => {
                              return (
                                <li
                                  key={iindex}
                                  onClick={() => {
                                    let val = "color";
                                    let temp: any = selectedFilter;
                                    if (temp[val].indexOf(m_val) !== -1) {
                                      temp[val].splice(
                                        temp[val].indexOf(m_val),
                                        1
                                      );
                                      setSelectedFilter({ ...temp });
                                    } else {
                                      temp[val].push(m_val);
                                      setSelectedFilter({ ...temp });
                                    }
                                  }}
                                  style={
                                    selectedFilter["color"].indexOf(m_val) !==
                                    -1
                                      ? {
                                          background: "black",
                                          color: "white",
                                        }
                                      : {}
                                  }
                                >
                                  {m_val}
                                </li>
                              );
                            })}
                        </ul>
                      </li>
                    </ul>
                  </div>
                )}
                {filterModal && (
                  <div
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setFilterModal(false);
                    }}
                    className={styles.close_surface}
                  ></div>
                )}
              </div> */}
            </div>
          }
        />
        <ContentBody style={{ padding: 0 }}>
          <Table
            head={product_table_head}
            filtervalue={filterData}
            body={data}
            setBody={setData}
            body_keys={product_access_keys}
            number={true}
            onActionClick={(val: string, id: string) => {
              setModal(true);
              setInFocus(id);
            }}
            onRowClick={(val: any) => {
              console.log(val);
              navigate(`/products/update/${val._id}`);
            }}
          />
        </ContentBody>
      </Content>
    </>
  );
};

export default Products;
