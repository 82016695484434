import { createContext, useEffect, useState } from "react";
import { sideBar } from "../utils/data";
import { findIndex } from "lodash";

const SideBarContext = createContext({});

type props = {
  children: any;
};

const SideBarProvider = (props: props) => {
  const [identifier, setIdentifier] = useState("");
  const [sidebar, setSidebar] = useState(sideBar);

  useEffect(() => {
    if (localStorage.getIntem("token-BO-ADMIN")) {
      let temp: any = sidebar;

      // check for blog
      if (localStorage.getItem("blog") === "false") {
        const bIndex = findIndex(temp, (val: any) => {
          return val.title === "Blogs";
        });

        temp.splice(bIndex, 1);
      }

      // check for contact
      if (localStorage.getItem("contact") === "false") {
        const cIndex = findIndex(temp, (val: any) => {
          return val.title === "Leads";
        });

        temp.splice(cIndex, 1);
      }

      // check for jobs
      if (localStorage.getItem("career") === "false") {
        const cIndex1 = findIndex(temp, (val: any) => {
          return val.title === "Jobs";
        });
        temp.splice(cIndex1, 1);

        const cIndex2 = findIndex(temp, (val: any) => {
          return val.title === "Applicants";
        });
        temp.splice(cIndex2, 1);
      }

      // check for ecommerce
      if (localStorage.getItem("ecommerce") === "false") {
        const eIndex = findIndex(temp, (val: any) => {
          return val.title === "Products";
        });
        temp.splice(eIndex, 1);

        const oIndex = findIndex(temp, (val: any) => {
          return val.title === "Orders";
        });
        temp.splice(oIndex, 1);

        const uIndex = findIndex(temp, (val: any) => {
          return val.title === "Users";
        });
        temp.splice(uIndex, 1);

        const dIndex = findIndex(temp, (val: any) => {
          return val.title === "Discounts";
        });
        temp.splice(dIndex, 1);
      }

      if (localStorage.getItem("service") === "false") {
        const sIndex = findIndex(temp, (val: any) => {
          return val.title === "Services";
        });
        temp.splice(sIndex, 1);
      }

      if (localStorage.getItem("caseStudy") === "false") {
        const cIndex = findIndex(temp, (val: any) => {
          return val.title === "Case-Study";
        });

        temp.splice(cIndex, 1);
      }

      setSidebar([...temp]);
    }
  }, []);

  const value = {
    identifier: identifier,
    sidebar: sidebar,
    setIdentifier: (val: any) => {
      setIdentifier(val);
    },
    updateSideBar: (val: any) => {
      localStorage.setItem("blog", val.blog);
      localStorage.setItem("contact", val.contact);
      localStorage.setItem("career", val.career);
      localStorage.setItem("ecommerce", val.ecommerce);
      localStorage.setItem("service", val.service);
      localStorage.setItem("caseStudy", val.caseStudy);

      let temp: any = sidebar;

      // check for blog
      if (!val.blog) {
        const bIndex = findIndex(temp, (val: any) => {
          return val.title === "Blogs";
        });

        temp.splice(bIndex, 1);
      }

      // check for contact
      if (!val.contact) {
        const cIndex = findIndex(temp, (val: any) => {
          return val.title === "Leads";
        });

        temp.splice(cIndex, 1);
      }

      // check for jobs
      if (!val.career) {
        const cIndex1 = findIndex(temp, (val: any) => {
          return val.title === "Jobs";
        });
        temp.splice(cIndex1, 1);

        const cIndex2 = findIndex(temp, (val: any) => {
          return val.title === "Applicants";
        });

        temp.splice(cIndex2, 1);
      }

      // check for ecommerce
      if (!val.ecommerce) {
        const eIndex = findIndex(temp, (val: any) => {
          return val.title === "Products";
        });
        temp.splice(eIndex, 1);

        const oIndex = findIndex(temp, (val: any) => {
          return val.title === "Orders";
        });
        temp.splice(oIndex, 1);

        const uIndex = findIndex(temp, (val: any) => {
          return val.title === "Users";
        });
        temp.splice(uIndex, 1);

        const dIndex = findIndex(temp, (val: any) => {
          return val.title === "Discounts";
        });
        temp.splice(dIndex, 1);
      }

      setSidebar([...temp]);
    },
  };

  return (
    <SideBarContext.Provider value={value}>
      {props.children}
    </SideBarContext.Provider>
  );
};

export { SideBarProvider, SideBarContext };
