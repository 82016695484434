import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import { toast } from "react-hot-toast";

const VerifyUser = () => {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    api({
      url: "auth/verifyEmail/" + params.id,
      method: "get",
      data: {},
      header: false,
    })
      .then((res: any) => {
        toast.success("Verified Successfully, Please login to continue.", {
          duration: 5000,
        });
        navigate("/");
      })
      .catch((err: any) => {
        console.log("error is : ", err);
        toast.error("Not able to verify the user.");
        navigate("/");
      });
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span className="loader"></span>
    </div>
  );
};

export default VerifyUser;
