import React, { useEffect, useState } from "react";
import Content from "../../../components/Content/Content";
import ContentHead from "../../../components/Content/ContentHead";
import { BLOG_NEW_PATH, BLOG_TITLE } from "../../../utils/constants";
import ContentBody from "../../../components/Content/ContentBody";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/Table";
import { blog_keys, blog_table_head } from "../../../utils/data";
import Modal from "../../../components/Modal";
import { toast } from "react-hot-toast";
import api from "../../../api";

const Blogs = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData]: any = useState([]);
  const [modal, setModal] = useState(false);
  const [inFocus, setInFocus] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    api({
      url: "content/getAllContent",
      method: "get",
      data: {},
      header: false,
    })
      .then((res) => {
        console.log(res.data.data.content);
        setData([...res.data.data.content]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const clickHandler = (event: any) => {
    let btn = event.target.name;

    if (btn === "btn1") {
      navigate(BLOG_NEW_PATH);
    }
  };

  const handleDelete = () => {
    setModal(false);

    toast.promise(
      new Promise((resolve, reject) => {
        api({
          url: "content/deleteContent/" + inFocus,
          method: "DELETE",
          data: {},
          header: true,
        })
          .then((res: any) => {
            getData();
            resolve(true);
          })
          .catch((err: any) => {
            reject(false);
          });
      }),
      {
        loading: "Deleting...",
        success: "Deleted successfully",
        error: "Error while deleting",
      }
    );
  };

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        onBtn1Click={() => {
          setModal(false);
          setInFocus("");
        }}
        onBtn2Click={handleDelete}
        state={modal}
      />
      <Content>
        <ContentHead
          title={BLOG_TITLE}
          showBtn1={true}
          btn1Name="btn1"
          btn1Text=" Add Blog"
          btn1type="filled"
          isIcon={true}
          onClickHandler={clickHandler}
        />
        <ContentBody style={{ padding: 0 }}>
          <>
            {loading && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  background: "rgba(0,0,0,.3)",
                  zIndex: 100000,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span className="loader"></span>
              </div>
            )}
            <Table
              head={blog_table_head}
              body={data}
              body_keys={blog_keys}
              number={true}
              onActionClick={(val: string, id: string) => {
                setModal(true);
                setInFocus(id);
              }}
              onRowClick={(val: any) => {
                navigate(`/blogs/update/${val._id}`);
              }}
            />
          </>
        </ContentBody>
      </Content>
    </>
  );
};

export default Blogs;
