import { useLocation } from "react-router-dom";
import { MapCollectionToId } from "./utils";

export const useQuery = () => {
  const location = useLocation();
  const searchParams: any = new URLSearchParams(location.search);

  const paramsObject: any = {};

  for (const [key, value] of searchParams.entries()) {
    // If the key already exists, convert it to an array
    if (paramsObject.hasOwnProperty(key)) {
      if (!Array.isArray(paramsObject[key])) {
        paramsObject[key] = [paramsObject[key]];
      }
      paramsObject[key].push(value);
    } else {
      paramsObject[key] = value;
    }
  }
  let query_string = "";
  console.log(paramsObject);
  Object.entries(paramsObject).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((val) => {
        if (key === "Category") {
          query_string += `productSubGroup3[]=${val}&`;
        } else if (key === "Collection") {
          query_string += `productCollection[]=${MapCollectionToId(val)}&`;
        } else if (key === "Color") {
          query_string += `colors[]=${val}&`;
        } else {
          query_string += `${key.toLowerCase()}=${val}&`;
        }
      });
    } else {
      if (key === "Category") {
        query_string += `productSubGroup3[]=${value}&`;
      } else if (key === "Collection") {
        query_string += `productCollection[]=${MapCollectionToId(value)}&`;
      } else if (key === "Color") {
        query_string += `colors[]=${value}&`;
      } else {
        query_string += `${key.toLowerCase()}=${value}&`;
      }
    }
  });
  console.log(query_string);
  return query_string;
};
