import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { sideBar } from "../utils/data";
import App from "./App";
import Login from "../components/Login";
import VerifyUser from "../components/VerifyUser";
import { useEffect, useState } from "react";
import api from "../api";
import { toast } from "react-hot-toast";

const Router = () => {
  const [loading, setLoading] = useState(true);
  const [login, setLogin] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("oren_auth_token")) {
      // api({
      //   url: "user/token",
      //   method: "get",
      //   data: {},
      //   header: true,
      // })
      //   .then(() => {
      //     setLogin(true);
      //     setLoading(false);
      //   })
      //   .catch(() => {
      //     toast.error("Session Expired, Please Login Again");
      //     setLogin(false);
      //     setLoading(false);
      //     localStorage.clear();
      //   });
      setLogin(true);
      setLoading(false);
    } else {
      setLogin(false);
      setLoading(false);
    }
  }, []);

  const children: any = [];

  sideBar.map((val) => {
    children.push({
      path: val.path,
      element: <val.element />,
    });
  });

  const routes = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      errorElement: <div>404</div>,
      children: children,
    },
  ]);

  const logoutRoutes = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/user/verifyEmail/:id",
      element: <VerifyUser />,
    },
  ]);

  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              height: "100vh",
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span className="loader"></span>
          </div>
        </>
      ) : (
        <RouterProvider router={login ? routes : logoutRoutes} />
      )}
    </>
  );
};

export default Router;
