import { useEffect, useState } from "react";
import Content from "../../../components/Content/Content";
import ContentBody from "../../../components/Content/ContentBody";
import ContentHead from "../../../components/Content/ContentHead";
import Loading from "../../../components/Loading";
import api from "../../../api";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import styles from "../../../styles/orderDetails.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

const OrderDetail = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [order, setOrder]: any = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    api({
      url: "order/" + params.id,
      method: "get",
      header: true,
    })
      .then(async (res: any) => {
        console.log("order data is ", res.data.order);

        const products = res.data.order.products;
        const products_array = [];

        for (let i = 0; i < products.length; i++) {
          try {
            const response = await api({
              url: "products/getProductById/" + products[i].productId,
              method: "GET",
              header: false,
            });

            products[i].productId = response.data.product;
          } catch (err) {
            toast.error("Something went wrong while fetching product details.");
            navigate("/orders");
          }
        }

        const userRes = await api({
          url: "user/" + res.data.order.userId?._id,
          method: "GET",
          header: true,
        });

        console.log("user Details are ::: ", userRes?.data?.user);

        setLoading(false);
        setOrder({
          ...res.data.order,
          products: products,
          userId: userRes.data.user,
        });
      })
      .catch((err: any) => {
        toast.error("Something went wrong while fetching order details.");
        console.log(err);
        navigate("/orders");
      });
  };

  return (
    <>
      <Loading state={loading} />
      <Content>
        <ContentHead
          title={
            <div className={styles.main_head}>
              Order Details{" "}
              {order?.paymentType === "retail" &&
                order?.paymentStatus === "false" &&
                order?.orderStatus &&
                order?.fulfilmentStatus === "received" && (
                  <div className={styles.tag_fail}>Failed</div>
                )}
              {order?.paymentType === "retail" &&
                order?.paymentStatus === "true" &&
                order?.orderStatus &&
                order?.fulfilmentStatus === "received" && (
                  <div className={styles.tag_received}>RECEIVED</div>
                )}
            </div>
          }
        />
        <ContentBody>
          {!loading && (
            <div className="grid">
              <div className="card">
                <div className="grid">
                  <div className={styles.products_head}>
                    <h3>Products</h3>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <p
                        style={{
                          background: "#eff5fc",
                          padding: "10px 15px",
                          borderRadius: "10px",
                          color: "#36619b",
                        }}
                      >
                        Tax : ₹{order?.tax}
                      </p>
                      <p
                        style={{
                          background: "#eff5fc",
                          padding: "10px 15px",
                          borderRadius: "10px",
                          color: "#36619b",
                        }}
                      >
                        SubTotal : ₹{order?.subTotal}
                      </p>
                      <p
                        style={{
                          background: "#eff5fc",
                          padding: "10px 15px",
                          borderRadius: "10px",
                          color: "#36619b",
                        }}
                      >
                        Total : ₹{order?.total}
                      </p>
                    </div>
                  </div>
                  <ul
                    style={{ padding: "10px" }}
                    className={styles.product_cards_container + " grid"}
                  >
                    {order.products.map((val: any, index: any) => {
                      return (
                        <li key={index}>
                          <div className={styles.product_image}>
                            <img
                              src={
                                process.env.REACT_APP_IMAGE +
                                val?.productId?.images[0].image
                              }
                              alt="product"
                            />
                          </div>
                          <div>
                            <span>{val?.productId?.productCategory}</span>
                            <h4>{val?.productId?.productName}</h4>
                          </div>
                          <div className={styles.product_last_part}>
                            <h5>Quantity</h5>
                            <p>{val.quantity}</p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="card">
                <div className="grid">
                  <h3>Customer Details</h3>
                  <div className="grid_2" style={{ gap: "20px" }}>
                    <div className={styles.customer_detail}>
                      <p>First Name: </p>
                      <p>{order.userId.firstName}</p>
                    </div>
                    <div className={styles.customer_detail}>
                      <p>Email: </p>
                      <p>{order.userId.email}</p>
                    </div>
                    <div className={styles.customer_detail}>
                      <p>Last Name:</p>
                      <p>{order.userId.lastName}</p>
                    </div>
                    <div className={styles.customer_detail}>
                      <p>Phone no.:</p>
                      <p>{order.userId.mobile.number || "-"}</p>
                    </div>
                    <div className={styles.address_container}>
                      <div className={styles.address_head}>
                        <h4>Shipping Address</h4>
                        <FontAwesomeIcon
                          style={{ cursor: "pointer" }}
                          icon={faCopy}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${order.shippingAddress.name}\n${order.shippingAddress.landmark}, ${order.shippingAddress.locality}, ${order.shippingAddress.city}, ${order.shippingAddress.state} - ${order.shippingAddress.pincode}`
                            );
                            toast.success("Copied to clipboard");
                          }}
                        />
                      </div>
                      <p style={{ paddingTop: "10px" }}>
                        {order.shippingAddress.name},
                      </p>
                      <p>{order.shippingAddress.landmark},</p>
                      <p>{order.shippingAddress.locality},</p>
                      <p>
                        {order.shippingAddress.city},{" "}
                        {order.shippingAddress.state} -{" "}
                        {order.shippingAddress.pincode}
                      </p>
                    </div>
                    <div className={styles.address_container}>
                      <div className={styles.address_head}>
                        <h4>Billing Address</h4>
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${order.billingAddress.name}\n${order.billingAddress.landmark}, ${order.billingAddress.locality}, ${order.billingAddress.city}, ${order.billingAddress.state} - ${order.billingAddress.pincode}`
                            );
                            toast.success("Copied to clipboard");
                          }}
                        />
                      </div>
                      <p style={{ paddingTop: "10px" }}>
                        {order.billingAddress.name},
                      </p>
                      <p>{order.billingAddress.landmark},</p>
                      <p>{order.billingAddress.locality},</p>
                      <p>
                        {order.billingAddress.city},{" "}
                        {order.billingAddress.state} -{" "}
                        {order.billingAddress.pincode}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="grid">
                  <h3>Payment Details</h3>
                  <div className="grid_2" style={{ gap: "20px" }}>
                    <div className={styles.customer_detail}>
                      <p>Payment Type: </p>
                      <p>{order.paymentType}</p>
                    </div>
                    <div className={styles.customer_detail}>
                      <p>Payment Status: </p>
                      <p>{order.paymentStatus}</p>
                    </div>
                    <div className={styles.customer_detail}>
                      <p>Order Status: </p>
                      <p>{order.orderStatus ? "True" : "False"}</p>
                    </div>
                    <div className={styles.customer_detail}>
                      <p>Fulfillment Status: </p>
                      <p>{order.fulfilmentStatus}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </ContentBody>
      </Content>
    </>
  );
};

export default OrderDetail;
