import styles from "../../styles/content.module.css";
import Button from "../Button";
// import Button from "../Custom/Button";

type props = {
  title: any;
  showBtn1?: boolean;
  showBtn2?: boolean;
  showBtn3?: boolean;
  btn1Text?: string;
  btn2Text?: string;
  btn3Text?: string;
  btn1type?: "outlined" | "filled" | "outlined_del" | "light_grey";
  btn2type?: "outlined" | "filled" | "outlined_del" | "light_grey";
  btn3type?: "outlined" | "filled" | "outlined_del" | "light_grey";
  onClickHandler?: any;
  btn1Name?: string;
  btn2Name?: string;
  btn3Name?: string;
  isIcon?: boolean;
  iconType?: string;
  customElement?: any;
};

const ContentHead = (props: props) => {
  console.log(props?.customElement);

  return (
    <div className={styles.content_container}>
      <h1>{props.title}</h1>
      <div style={{ flexGrow: 1 }}></div>
      {props?.customElement}
      {props?.showBtn3 && (
        <Button
          name={props?.btn3Name}
          type={props?.btn3type}
          text={props?.btn3Text}
          style={{ marginRight: "10px" }}
          clickHandler={props.onClickHandler}
          isIcon={props?.isIcon ? props?.isIcon : false}
          iconType={props?.iconType}
        />
      )}
      {props?.showBtn2 && (
        <Button
          name={props?.btn2Name}
          type={props?.btn2type}
          text={props?.btn2Text}
          style={{ marginRight: "10px" }}
          clickHandler={props.onClickHandler}
          isIcon={props?.isIcon ? props?.isIcon : false}
          iconType={props?.iconType}
        />
      )}
      {props?.showBtn1 && (
        <Button
          name={props?.btn1Name}
          type={props?.btn1type}
          text={props?.btn1Text}
          clickHandler={props.onClickHandler}
          isIcon={props?.isIcon ? props?.isIcon : false}
          iconType={props?.iconType}
        />
      )}
    </div>
  );
};

export default ContentHead;
