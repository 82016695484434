import React, { useEffect, useState } from "react";
import Content from "../../../components/Content/Content";
import ContentHead from "../../../components/Content/ContentHead";
import { USER_DETAILS } from "../../../utils/constants";
import ContentBody from "../../../components/Content/ContentBody";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import api from "../../../api";
import toast from "react-hot-toast";
import styles from "../../../styles/user.module.css";

const UserDetails = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [user, setUser]: any = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api({
      url: "user/" + params.id,
      method: "GET",
      header: true,
    })
      .then((res: any) => {
        setUser(res.data.user);
        setLoading(false);
      })
      .catch((err: any) => {
        toast.error("Something went wrong while fetching user data.");
        navigate("/user");
      });
  };

  return (
    <>
      <Loading state={loading} />
      <Content>
        <ContentHead
          title={USER_DETAILS}
          showBtn1={true}
          btn1Text="Go Back"
          btn1Name="btn1"
          btn1type="filled"
          onClickHandler={() => navigate("/user")}
        />
        <ContentBody>
          {!loading && (
            <div className="grid">
              <div className="card">
                <div className="grid">
                  <h3>User Details</h3>
                  <div className="grid_2" style={{ gap: "20px" }}>
                    <div className={styles.customer_detail}>
                      <p>First Name: </p>
                      <p>{user?.firstName || "-"}</p>
                    </div>
                    <div className={styles.customer_detail}>
                      <p>Email: </p>
                      <p>{user?.email || "-"}</p>
                    </div>
                    <div className={styles.customer_detail}>
                      <p>Last Name: </p>
                      <p>{user?.lastName || "-"}</p>
                    </div>
                    <div className={styles.customer_detail}>
                      <p>Mobile: </p>
                      <p>{user?.mobile?.number || "-"}</p>
                    </div>
                    <div className={styles.customer_detail}>
                      <p>Company Name: </p>
                      <p>{user?.company || "-"}</p>
                    </div>
                    <div className={styles.customer_detail}>
                      <p>Alternate Mobile: </p>
                      <p>{user?.alternateMobile?.number || "-"}</p>
                    </div>
                    <div className={styles.customer_detail}>
                      <p>Designation: </p>
                      <p>{user?.designation || "-"}</p>
                    </div>
                    <div className={styles.customer_detail}>
                      <p>Landline: </p>
                      <p>{user?.landline || "-"}</p>
                    </div>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <h4>Billing Addresses</h4>
                    {user?.billingAddress.filter((val: any) => val).length ===
                    0 ? (
                      <p className={styles.address_not_found}>Not Found</p>
                    ) : (
                      <ul className={styles.address_list}>
                        {user?.billingAddress
                          .filter((val: any) => val)
                          .map((val: any, index: any) => {
                            return (
                              <li key={index} className={styles.address}>
                                <p>{val?.name}</p>
                                <p>
                                  {val?.landmark}, {val?.locality}
                                </p>
                                <p>
                                  {val?.city}, {val?.state}, {val?.pincode}
                                </p>
                              </li>
                            );
                          })}
                      </ul>
                    )}
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <h4>Shipping Addresses</h4>
                    {user?.shippingAddress.filter((val: any) => val).length ===
                    0 ? (
                      <p className={styles.address_not_found}>Not Found</p>
                    ) : (
                      <ul></ul>
                    )}
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="grid">
                  <h3>Orders</h3>
                  <ul className={styles.orders_container}>
                    {user?.orderId.map((order: any, index: number) => {
                      return (
                        <li
                          key={index}
                          onClick={() => navigate("/orders/" + order?._id)}
                        >
                          <div className={styles.order_head}>
                            <h4>#{order?.orderRefId}</h4>
                            <p>Total : ₹{order?.total}</p>
                          </div>
                          <div className={styles.products}>
                            {order?.products.map((product: any, index: any) => {
                              return (
                                <div key={index} className={styles.product}>
                                  <div className={styles.product_image}>
                                    <img
                                      src={
                                        process.env.REACT_APP_IMAGE +
                                        product.productId?.images[0].image
                                      }
                                      alt={""}
                                    />
                                  </div>
                                  <div className={styles.about_product}>
                                    {product?.productId?.productName}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </ContentBody>
      </Content>
    </>
  );
};

export default UserDetails;
