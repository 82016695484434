import api from "../api";

export function randomString(length: any) {
  let chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}
export const MapCollectionToId = (name: any) => {
  //   console.log(name);
  if (name === "Daydream") return "64a3e8f650e8979390d20218";
  else if (name === "Earth Serve") return "64e5d9b3f6676b01af2da456";
  else if (name === "Serenity") return "64e5d97af6676b01af2da44c";
  else if (name === "Grace") return "64e5d997f6676b01af2da450";
  else if (name === "Gourmet") return "64e5d99ef6676b01af2da453";
  else if (name === "Ethereal") return "65dcaea9e4c71edb45bb5581";
  else if (name === "Terra Serve") return "65dcae8be4c71edb45bb5580";
};
export const MapCollectionToName = (id: any) => {
  if (id === "64a3e8f650e8979390d20218") return "Daydream";
  else if (id === "64e5d9b3f6676b01af2da456") return "Earth Serve";
  else if (id === "64e5d97af6676b01af2da44c") return "Serenity";
  else if (id === "64e5d997f6676b01af2da450") return "Grace";
  else if (id === "64e5d99ef6676b01af2da453") return "Gourmet";
  else if (id === "65dcaea9e4c71edb45bb5581") return "Ethereal";
  else if (id === "65dcae8be4c71edb45bb5580") return "Terra Serve";
};

export const getUnique = async (title: string): Promise<any> => {
  try {
    console.log(title);

    const response = await api({
      url: "products/getUnique",
      method: "get",
      header: false,
    });

    console.log(response.data);

    if (title === "Category") {
      return response.data.productSubGroup3;
    } else if (title === "Collection") {
      return [
        "Daydream",
        "Earth Serve",
        "Serenity",
        "Grace",
        "Gourmet",
        "Ethereal",
        "Terra Serve",
      ];
    } else if (title === "Color") {
      return response.data.color;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
    // If you want to propagate the error, you can rethrow it
    throw error;
    // Alternatively, you can return a default value or handle the error in another way
    // return [];
  }
};

export const getSKU = (productId: string) => {
  // console.log(productId);
  return api({
    url: "products/getProductById/" + productId,
    method: "get",
    data: {},
    header: false,
  })
    .then((res: any) => {
      // console.log(res.data.product.productCode);
      let temp = { _id: productId, SKU: res.data.product.productCode };
      return temp;
    })
    .catch((error: any) => {
      console.log(error);
    });
};
