import styles from "../../styles/fieldsContainer.module.css";

const FieldsContainer = ({ children }: any) => {
  return <div className={styles.container}>{children}</div>;
};

export const FieldCard = ({ children, take, heading }: any) => {
  return (
    <div className={styles.card} style={{ gridColumn: take }}>
      <div className={styles.heading}>
        <h3>{heading}</h3>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default FieldsContainer;
