import React from "react";
import Content from "../../../components/Content/Content";
import ContentHead from "../../../components/Content/ContentHead";
import { PRODUCT_NEW } from "../../../utils/constants";
import ContentBody from "../../../components/Content/ContentBody";
import FieldsContainer, {
  FieldCard,
} from "../../../components/FieldsContainer";
import InputField from "../../../components/InputField";
import TextEditor from "../../../components/TextEditor";
import bStyles from "../../../styles/blog.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faPlus } from "@fortawesome/free-solid-svg-icons";
import withRouter from "../../../components/withRouter";
import api from "../../../api";
import { toast } from "react-hot-toast";
import { getFileName } from "../../../utils/getFileName";
import UploadImage from "../../../utils/UploadImage";
import styles from "../../../styles/products.module.css";
import { getSKU } from "../../../utils/utils";

type IState = {
  loading: boolean;
  collections: any[];
  colorVariantSKU: any[];
  sizeVariantSKU: any[];
  // Product Data
  status: string;
  supplierId: string;
  sku: string;
  brandName: string;
  internalBrand: string;
  alternateBrand: string;
  productCollection: string;
  productCode: string;
  supplierProductCode: string;
  alternateModelNo: string;
  productCodeAlias1: string;
  productCodeAlias2: string;
  productCodeAlias3: string;
  productCodeAlias4: string;
  productName: string;
  productType: string;
  productSpecShort: string;
  productSpecLong: string;
  madeUpMaterial: string;
  subMadeUpMaterial: string;
  color: string;
  image: any[];
  length: string;
  width: string;
  height: string;
  depth: string;
  diameter: string;
  capacity: string;
  thickness: string;
  weight: string;
  voltage: string;
  power: string;
  productSolution: string[];
  productCategory: string;
  productSubGroup1: string;
  productSubGroup2: string;
  productSubGroup3: string;
  productSubGroup4: string;
  productSubGroup5: string;
  purchaseCurrency: string;
  listPrice: string;
  discount: string;
  netPrice: string;
  palletPrice: string;
  setQty: string;
  purchaseCasePackQuantity: string;
  salesCasePackQuantity: string;
  purchaseMasterCartonPackQuantity: string;
  salesMasterCartonPackQuantity: string;
  palletQuantity: string;
  gstRelevant: boolean;
  gstRate: string;
  hsnCode: string;
  priceApplicableFrom: string;
  priceApplicableTo: string;
  batchNo: boolean;
  countryOfOrigin: string;
  countryOfShipment: string;
  boxWeight: string;
  boxLength: string;
  boxWidth: string;
  boxHeight: string;
  inventoryLevelMinimum: string;
  inventoryLevelMaximum: string;
  reorderLevel: string;
  safetyStock: string;
  orderMultiple: string;
  minimumOrderQuantity: string;
  purchaseLeadTime: string;
  purchaseTransitTime: string;
  dutyStructure: string;
  stockInHand: string;
  tag: string;
  tags: any[];
  productCodeStatus: boolean;
  productNameStatus: boolean;
  productSG1Status: boolean;
  productSG3Status: boolean;
  productSG4Status: boolean;
  productCollectionStatus: boolean;
  colorStatus: boolean;
  salesCPQStatus: boolean;
  weightStatus: boolean;
  heightStatus: boolean;
  listPriceStatus: boolean;
  colorVariant: string[];
  sizeVariant: string[];
  color_variant: string[];
  size_variant: string[];
};

class AddUpdateProduct extends React.Component<any, IState> {
  state = {
    loading: true,
    collections: [],
    colorVariantSKU: [],
    sizeVariantSKU: [],
    // Product Data
    status: "",

    supplierId: "",
    sku: "",
    brandName: "",
    internalBrand: "",
    alternateBrand: "",
    productCollection: "",
    productCode: "",
    supplierProductCode: "",
    alternateModelNo: "",
    productCodeAlias1: "",
    productCodeAlias2: "",
    productCodeAlias3: "",
    productCodeAlias4: "",
    productName: "",
    productType: "",
    productSpecShort: "",
    productSpecLong: "",
    madeUpMaterial: "",
    subMadeUpMaterial: "",
    color: "",
    image: [],
    length: "",
    width: "",
    height: "",
    depth: "",
    diameter: "",
    capacity: "",
    thickness: "",
    weight: "",
    voltage: "",
    power: "",
    productSolution: [],
    productCategory: "",
    productSubGroup1: "",
    productSubGroup2: "",
    productSubGroup3: "",
    productSubGroup4: "",
    productSubGroup5: "",
    purchaseCurrency: "",
    listPrice: "",
    discount: "",
    netPrice: "",
    palletPrice: "",
    setQty: "",
    purchaseCasePackQuantity: "",
    salesCasePackQuantity: "",
    purchaseMasterCartonPackQuantity: "",
    salesMasterCartonPackQuantity: "",
    palletQuantity: "",
    gstRelevant: false,
    gstRate: "",
    hsnCode: "",
    priceApplicableFrom: "",
    priceApplicableTo: "",
    batchNo: false,
    countryOfOrigin: "",
    countryOfShipment: "",
    boxWeight: "",
    boxLength: "",
    boxWidth: "",
    boxHeight: "",
    inventoryLevelMinimum: "",
    inventoryLevelMaximum: "",
    reorderLevel: "",
    safetyStock: "",
    orderMultiple: "",
    minimumOrderQuantity: "",
    purchaseLeadTime: "",
    purchaseTransitTime: "",
    dutyStructure: "",
    stockInHand: "",
    tag: "",
    tags: [],
    productCodeStatus: false,
    productNameStatus: false,
    productSG1Status: false,
    productSG3Status: false,
    productSG4Status: false,
    productCollectionStatus: false,
    colorStatus: false,
    salesCPQStatus: false,
    weightStatus: false,
    heightStatus: false,
    listPriceStatus: false,
    colorVariant: [],
    sizeVariant: [],

    color_variant: [],
    size_variant: [],
  };

  componentDidMount(): void {
    this.getCollection();
    this.getVariant();
    const { status } = this.state;
    console.log(status);
  }

  getCollection = () => {
    api({
      url: "collection/getAllCollection",
      method: "get",
      data: {},
      header: false,
    })
      .then((res) => {
        if (this.props.router.params.id) {
          this.setState({ collections: res.data.data.data });
          // console.log(res.data.data.data);
          this.getProduct();
        } else {
          this.setState({ loading: false, collections: res.data.data.data });
          // console.log(res.data.data.data);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong while fetching the collections.");
        this.setState({ loading: false });
      });
  };
  componentDidUpdate(prevProps: any, prevState: any) {
    // Check if someValue has changed
    if (
      this.state.productSubGroup1 !== prevState.productSubGroup1 ||
      this.state.productSubGroup3 !== prevState.productSubGroup3
    ) {
      this.getVariant();
    }
  }

  getVariant = () => {
    api({
      url: `products/filter?productSubGroup1[]=${this.state.productSubGroup1}&productSubGroup3[]=${this.state.productSubGroup3}`,
      method: "get",
      data: {},
      header: false,
    })
      .then((res) => {
        const response = res.data.data;
        let result: any = [];
        let result2: any = [];
        response.forEach((val: any) => {
          result.push({
            _id: val._id,
            SKU: val.sku,
            imageUrl: val.images[0]?.image,
            color: val.color,
            slug: val.slug,
          });
          result2.push({
            _id: val._id,
            SKU: val.sku,
            imageUrl: val.images[0]?.image,
            size:
              val?.length && val?.width && val?.height
                ? `${val?.length} x ${val?.width} x ${val?.height} `
                : `${val?.diameter} x ${val?.height}`,
            slug: val.slug,
          });
        });
        // console.log(result);
        this.setState({ colorVariantSKU: result, sizeVariantSKU: result2 });
      })
      .catch((err) => {
        toast.error("Something went wrong while fetching data.");
        console.log(err);
        this.setState({ loading: false });
      });
  };

  getProduct = () => {
    api({
      url: "products/getProductById/" + this.props.router.params.id,
      method: "get",
      data: {},
      header: false,
    })
      .then((res: any) => {
        // console.log(res);
        let temp = res.data.product;

        for (let key in temp) {
          if (temp[key] === null) {
            temp[key] = "";
          }
        }

        delete temp._v;
        delete temp._id;

        this.setState({
          ...res.data.product,
          image: [
            ...res.data.product.images.map((val: any) => ({
              url: val.image,
              obj: "",
            })),
          ],
          loading: false,
          collections: this.state.collections,
        });

        let newtemp = res.data.product.colorVariant;
        console.log(newtemp);
        let result: any = [];
        const promises = newtemp.map((val: any) => {
          return getSKU(val.productId);
        });

        Promise.all(promises)
          .then((skus: any[]) => {
            result.push(...skus);
            // console.log(result);
            this.setState({ color_variant: result });
          })
          .catch((error: any) => {
            console.error("Error:", error);
          });

        let newtemp2 = res.data.product.sizeVariant;
        // console.log(newtemp2);
        let result2: any = [];
        const new_promises = newtemp2.map((val: any) => {
          return getSKU(val.productId);
        });

        Promise.all(new_promises)
          .then((skus: any[]) => {
            result2.push(...skus);
            // console.log(result2);
            this.setState({ size_variant: result2 });
          })
          .catch((error: any) => {
            console.error("Error:", error);
          });

        // replace all the null values with the empty string in temp
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  handleChange = (e: any) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
    // console.log(this.state.color_variant, this.state.size_variant);
  };

  checkData = () => {
    // mandatory fields product code and product name
    if (
      // this.state.status === "" ||
      this.state.sku === "" ||
      this.state.productName === "" ||
      this.state.productSubGroup1 === "" ||
      this.state.productSubGroup3 === "" ||
      this.state.productSubGroup4 === "" ||
      this.state.listPrice === "" ||
      this.state.color === "" ||
      this.state.salesCasePackQuantity === "" ||
      this.state.weight === "" ||
      this.state.height === "" ||
      this.state.listPrice === ""
    ) {
      toast.error("Please fill all the mandatory fields.");

      if (this.state.sku === "") {
        // sku
        this.setState({ productCodeStatus: true });
      }

      if (this.state.productName === "") {
        // product name
        this.setState({ productNameStatus: true });
      }

      if (this.state.productSubGroup1 === "") {
        // product sub group 1
        this.setState({ productSG1Status: true });
      }

      if (this.state.productSubGroup3 === "") {
        // product sub group 3
        this.setState({ productSG3Status: true });
      }

      if (this.state.productSubGroup4 === "") {
        // product sub group 4
        this.setState({ productSG4Status: true });
      }

      // if (this.state.productCollection === "") {
      //   // product collection
      //   this.setState({ productCollectionStatus: true });
      // }

      if (this.state.color === "") {
        // color
        this.setState({ colorStatus: true });
      }

      if (this.state.salesCasePackQuantity === "") {
        // sales case pack quantity
        this.setState({ salesCPQStatus: true });
      }

      if (this.state.weight === "") {
        // weight
        this.setState({ weightStatus: true });
      }

      if (this.state.height === "") {
        // height
        this.setState({ heightStatus: true });
      }

      if (this.state.listPrice === "") {
        // list price
        this.setState({ listPriceStatus: true });
      }

      return false;
    }
    return true;
  };

  handleClick = (e: any) => {
    let clicked = e.target.name;

    if (clicked === "btn1" || clicked === "btn2") {
      if (this.checkData()) {
        this.prepareData();
      }
    }

    if (clicked === "btn3") {
      this.props.router.navigate("/products");
    }
  };

  prepareData = () => {
    const data: any = { ...this.state };

    // remove the extra data
    delete data.loading;
    delete data.color_variant;
    delete data.size_variant;
    delete data.collections;
    delete data.colorVariantSKU;
    delete data.sizeVariantSKU;

    delete data.supplierId;
    delete data.productCodeStatus;
    delete data.productNameStatus;
    delete data.tag;

    if (this.props.router.params.id) {
      this.update_product(data);
    } else {
      this.create_product(data);
    }
  };

  create_product = (data: any) => {
    this.setState({ loading: true });

    this.uploadMediaUrl()
      .then((res: any) => {
        api({
          url: "products/addProduct",
          method: "post",
          data: {
            ...data,
            images:
              res.length !== 0
                ? [...res.map((val: any) => ({ image: val }))]
                : "",
          },
          header: true,
        })
          .then((res: any) => {
            this.setState({ loading: false });
            toast.success("Product created successfully.");
            this.props.router.navigate("/products");
          })
          .catch((error: any) => {
            this.setState({ loading: false });
          });
      })
      .catch(() => {});
  };

  update_product = (data: any) => {
    this.setState({ loading: true });

    this.uploadMediaUrl()
      .then((res: any) => {
        api({
          url: "products/updateProduct/" + this.props.router.params.id,
          method: "patch",
          data: {
            ...data,
            images:
              res.length !== 0
                ? [...res.map((val: any) => ({ image: val }))]
                : "",
          },
          header: true,
        })
          .then((res: any) => {
            this.setState({ loading: false });
            toast.success("Product updated successfully.");
            this.props.router.navigate(-1);
          })
          .catch((error: any) => {
            this.setState({ loading: false });
          });
      })
      .catch(() => {});
  };

  uploadMediaUrl = () => {
    let temp: any = this.state.image;

    return new Promise((resolve, reject) => {
      let temp_url: any = [];

      for (let i = 0; i < temp.length; i++) {
        // means when it's an object
        if (temp[i].obj) {
          const filename = getFileName(temp[i].obj);

          try {
            let _ = UploadImage({ file: temp[i].obj, name: filename });
            temp_url.push(filename);
          } catch (err) {
            toast.error("error occurred while uploading media url.");
            reject(err);
          }
        } else {
          temp_url.push(temp[i].url);
        }
      }

      resolve(temp_url);
    });
  };

  handleImage = (e: any) => {
    this.setState({ image: [...this.state.image, e.target.files[0]] });

    this.setState({
      image: [
        ...this.state.image,
        {
          obj: e.target.files[0],
          url: URL.createObjectURL(e.target.files[0]),
        },
      ],
    });
  };

  removeImage = (index: number) => {
    let images = this.state.image;
    images.splice(index, 1);
    this.setState({ image: images });
  };

  handleToggle = (e: any) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      this.setState({ status: "active" });
    } else {
      this.setState({ status: "inactive" });
    }
  };

  render() {
    return (
      <>
        {this.state.loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: "rgba(0,0,0,.3)",
              zIndex: 100000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="loader"></span>
          </div>
        )}

        <Content>
          <ContentHead
            title={PRODUCT_NEW}
            showBtn1={true}
            showBtn2={true}
            showBtn3={true}
            btn1Name="btn1"
            btn2Name="btn2"
            btn3Name="btn3"
            btn1Text="Save"
            btn2Text="Save as Draft"
            btn3Text="Cancel"
            btn1type="filled"
            btn2type="outlined"
            btn3type="outlined_del"
            onClickHandler={this.handleClick}
          />
          <ContentBody>
            <FieldsContainer>
              {/* Details */}

              <FieldCard take={"1/4"} heading={"Details"}>
                <div className={bStyles.toggle}>
                  <label className={bStyles.switch}>
                    <input
                      type="checkbox"
                      onChange={this.handleToggle}
                      checked={this.state.status === "active"}
                    />
                    <span
                      className={`${bStyles.slider} ${bStyles.round}`}
                    ></span>
                  </label>
                </div>
                <InputField
                  isLabel={true}
                  value={this.state.sku}
                  type="text"
                  lable="SKU"
                  name="sku"
                  onChange={this.handleChange}
                  isRequired={true}
                  error={this.state.productCodeStatus}
                  errorText="Product Code is mandatory."
                />

                <InputField
                  isLabel={true}
                  value={this.state.productName}
                  type="text"
                  lable="Product Name"
                  name="productName"
                  onChange={this.handleChange}
                  isRequired={true}
                  error={this.state.productNameStatus}
                  errorText="Product Name is mandatory."
                />

                {/* <InputField
                  isLabel={true}
                  value={this.state.supplierId}
                  type="text"
                  lable="Supplier Id"
                  name="supplierId"
                  onChange={this.handleChange}
                /> */}

                {/* <InputField
                  isLabel={true}
                  value={this.state.productType}
                  type="text"
                  lable="Product Type"
                  name="productType"
                  onChange={this.handleChange}
                /> */}

                {/* <InputField
                  isLabel={true}
                  value={this.state.productCategory}
                  type="text"
                  lable="Product Category"
                  name="productCategory"
                  onChange={this.handleChange}
                /> */}

                <InputField
                  isLabel={true}
                  value={this.state.productCollection}
                  type="select"
                  options={this.state.collections}
                  accessText="collectionName"
                  accessValue="_id"
                  lable="Product Collection"
                  name="productCollection"
                  isRequired={true}
                  onChange={this.handleChange}
                  error={this.state.productCollectionStatus}
                  errorText="Product Collection is mandatory."
                />
                {/* <InputField
                  isLabel={true}
                  value={this.state.supplierProductCode}
                  type="text"
                  lable="Supplier Product Code"
                  name="supplierProductCode"
                  onChange={this.handleChange}
                /> */}
                <InputField
                  isLabel={true}
                  value={this.state.alternateModelNo}
                  type="text"
                  lable="Alternate Model No."
                  name="alternateModelNo"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.productSubGroup1}
                  type="text"
                  lable="Product Sub Group 1"
                  name="productSubGroup1"
                  isRequired={true}
                  onChange={this.handleChange}
                  error={this.state.productSG1Status}
                  errorText="Product Sub Group 1 is mandatory."
                />
                <InputField
                  isLabel={true}
                  value={this.state.productSubGroup2}
                  type="text"
                  lable="Product Sub Group 2"
                  name="productSubGroup2"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.productSubGroup3}
                  type="text"
                  lable="Product Sub Group 3"
                  isRequired={true}
                  name="productSubGroup3"
                  onChange={this.handleChange}
                  error={this.state.productSG3Status}
                  errorText="Product Sub Group 3 is mandatory."
                />
                <InputField
                  isLabel={true}
                  value={this.state.productSubGroup4}
                  type="text"
                  lable="Product Sub Group 4"
                  isRequired={true}
                  name="productSubGroup4"
                  onChange={this.handleChange}
                  error={this.state.productSG4Status}
                  errorText="Product Sub Group 4 is mandatory."
                />
                <InputField
                  isLabel={true}
                  value={this.state.productSubGroup5}
                  type="text"
                  lable="Product Sub Group 5"
                  name="productSubGroup5"
                  onChange={this.handleChange}
                />
                <div>
                  <InputField
                    isLabel={true}
                    value={""}
                    type="select"
                    lable="Concept"
                    name="productSolution"
                    onChange={(e: any) => {
                      let temp: any = [...this.state.productSolution];
                      // console.log(e.target.value);
                      temp.push(e.target.value);
                      this.setState({ productSolution: temp });
                    }}
                    options={[
                      { title: "Japanese", value: "japanese" },
                      { title: "Tapas", value: "tapas" },
                      { title: "Italian", value: "italian" },
                      { title: "Indian", value: "indian" },
                      { title: "Global", value: "global" },
                      { title: "Buffet Display", value: "buffet_display" },
                      { title: "Drinkware", value: "drinkware" },
                      { title: "Dessert", value: "dessert" },
                      { title: "Mughlai", value: "mughlai" },
                      { title: "In Room Dining", value: "in_room_dining" },
                    ].filter((val: any) => {
                      if (this.state.productSolution.length === 0) {
                        return true;
                      } else {
                        // @ts-ignore
                        return !this.state.productSolution.includes(val.value);
                      }
                    })}
                    accessText="title"
                    accessValue="value"
                  />
                  {this.state.productSolution.length !== 0 && (
                    <div
                      className={styles.tags_list}
                      style={{ paddingTop: "10px" }}
                    >
                      {this.state.productSolution.map((val: any, index) => {
                        return (
                          <p key={index}>
                            {val}
                            <FontAwesomeIcon
                              icon={faClose}
                              color="#36619b"
                              onClick={() => {
                                let temp = [...this.state.productSolution];
                                temp.splice(index, 1);
                                this.setState({ productSolution: temp });
                              }}
                            />
                          </p>
                        );
                      })}
                    </div>
                  )}
                </div>
                <InputField
                  isLabel={true}
                  value={this.state.productCodeAlias1}
                  type="text"
                  lable="Product Alias 1"
                  name="productCodeAlias1"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.productCodeAlias2}
                  type="text"
                  lable="Product Alias 2"
                  name="productCodeAlias2"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.productCodeAlias3}
                  type="text"
                  lable="Product Alias 3"
                  name="productCodeAlias3"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.productCodeAlias4}
                  type="text"
                  lable="Product Alias 4"
                  name="productCodeAlias4"
                  onChange={this.handleChange}
                />
                {/* <InputField
                  isLabel={true}
                  value={this.state.status}
                  type="select"
                  options={[{ value: "active" }, { value: "inactive" }]}
                  accessText="value"
                  accessValue="value"
                  lable="Product Status"
                  name="status"
                  isRequired={true}
                  onChange={this.handleChange}
                  error={this.state.productCollectionStatus}
                  errorText="Product status is mandatory."
                /> */}
                <div></div>
                <div className={styles.tags}>
                  <InputField
                    isLabel={true}
                    value={this.state.tag}
                    type="text"
                    lable="Tags"
                    name="tag"
                    onChange={(e: any) => {
                      this.setState({ tag: e.target.value });
                    }}
                    onKeyPress={(e: any) => {
                      if (e.key === "Enter") {
                        this.setState({
                          tags: [...this.state.tags, this.state.tag],
                          tag: "",
                        });
                      }
                    }}
                  />
                  <div className={styles.tags_list}>
                    {this.state.tags.map((val: any, index: any) => {
                      return (
                        <p key={index}>
                          {val}
                          <FontAwesomeIcon
                            icon={faClose}
                            color="#36619b"
                            onClick={() => {
                              let temp = [...this.state.tags];
                              temp.splice(index, 1);
                              this.setState({ tags: temp });
                            }}
                          />
                        </p>
                      );
                    })}
                  </div>
                </div>

                <div>
                  <InputField
                    isLabel={true}
                    value={""}
                    type="select"
                    options={this.state.colorVariantSKU}
                    accessText="SKU"
                    accessValue="_id"
                    lable="Color Variant"
                    name="colorVariant"
                    isRequired={false}
                    onChange={(e: any) => {
                      let temp: any = [...this.state.color_variant];
                      let temp2: any = [...this.state.colorVariant];

                      let arr: any = e.target.value.split(":");
                      temp.push({ _id: arr[0], SKU: arr[1] });
                      temp2.push({
                        color: arr[2],
                        imageUrl: arr[4],
                        slug: arr[5],
                        productId: arr[0],
                      });
                      // console.log(temp);
                      this.setState({ color_variant: temp });
                      this.setState({ colorVariant: temp2 });
                    }}
                  />
                  {this.state.color_variant.length !== 0 && (
                    <div
                      className={styles.tags_list}
                      style={{ paddingTop: "10px" }}
                    >
                      {this.state.color_variant.map((val: any, index) => {
                        return (
                          <p key={index}>
                            {val.SKU}
                            <FontAwesomeIcon
                              icon={faClose}
                              color="#36619b"
                              onClick={() => {
                                let temp = [...this.state.color_variant];
                                let temp2 = [...this.state.colorVariant];
                                temp2.splice(index, 1);
                                temp.splice(index, 1);
                                this.setState({ color_variant: temp });
                                this.setState({ colorVariant: temp2 });
                              }}
                            />
                          </p>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div>
                  <InputField
                    isLabel={true}
                    value={""}
                    type="select"
                    options={this.state.sizeVariantSKU}
                    accessText="sizeVariant"
                    accessValue="_id"
                    lable="Size Variant"
                    name="sizeVariant"
                    isRequired={false}
                    onChange={(e: any) => {
                      let temp: any = [...this.state.size_variant];
                      let temp2: any = [...this.state.sizeVariant];

                      let arr: any = e.target.value.split(":");
                      temp.push({ _id: arr[0], SKU: arr[1] });
                      temp2.push({
                        size: arr[3],
                        imageUrl: arr[4],
                        slug: arr[5],
                        productId: arr[0],
                      });
                      // console.log(temp);
                      this.setState({ size_variant: temp });
                      this.setState({ sizeVariant: temp2 });
                    }}
                  />
                  {this.state.size_variant.length !== 0 && (
                    <div
                      className={styles.tags_list}
                      style={{ paddingTop: "10px" }}
                    >
                      {this.state.size_variant.map((val: any, index) => {
                        return (
                          <p key={index}>
                            {val.SKU}
                            <FontAwesomeIcon
                              icon={faClose}
                              color="#36619b"
                              onClick={() => {
                                let temp = [...this.state.size_variant];
                                let temp2 = [...this.state.sizeVariant];
                                temp2.splice(index, 1);
                                temp.splice(index, 1);
                                this.setState({ size_variant: temp });
                                this.setState({ sizeVariant: temp2 });
                              }}
                            />
                          </p>
                        );
                      })}
                    </div>
                  )}
                </div>
              </FieldCard>

              {/* Brand Details */}
              {/* <FieldCard take={"1/4"} heading={"Brand Details"}>
                <InputField
                  isLabel={true}
                  value={this.state.brandName}
                  type="text"
                  lable="Brand Name"
                  name="brandName"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.internalBrand}
                  type="text"
                  lable="Internal Brand"
                  name="internalBrand"
                  onChange={this.handleChange}
                />
              </FieldCard> */}

              {/* Pricing */}
              <FieldCard take={"1/4"} heading={"Pricing"}>
                {/* <div
                  style={{
                    display: "flex",
                    marginLeft: "5px",
                    marginTop: "15px",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={this.state.gstRelevant}
                    style={{ fontSize: "2rem" }}
                    onChange={(event: any) =>
                      this.setState({
                        gstRelevant: event.target.checked,
                      })
                    }
                  />
                  <p style={{ fontSize: "16px" }}>GST Relevant</p>
                </div>
                <div></div>
                <div></div> */}
                {/* <InputField
                  isLabel={true}
                  value={this.state.purchaseCurrency}
                  type="text"
                  lable="Purchase Currency"
                  name="purchaseCurrency"
                  onChange={this.handleChange}
                /> */}
                <InputField
                  isLabel={true}
                  value={this.state.listPrice}
                  type="text"
                  lable="Listing Price"
                  name="listPrice"
                  isRequired={true}
                  onChange={this.handleChange}
                  error={this.state.listPriceStatus}
                  errorText="List Price is mandatory."
                />
                <InputField
                  isLabel={true}
                  value={this.state.discount}
                  type="text"
                  lable="Discount"
                  name="discount"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.netPrice}
                  type="text"
                  lable="Net Price"
                  name="netPrice"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.palletPrice}
                  type="text"
                  lable="Pallet Price"
                  name="palletPrice"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.setQty}
                  type="text"
                  lable="Quantity"
                  name="setQty"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.purchaseCasePackQuantity}
                  type="text"
                  lable="Purchase Case Pack Quantity"
                  name="purchaseCasePackQuantity"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.salesCasePackQuantity}
                  type="text"
                  lable="Sales Case Pack Quantity"
                  name="salesCasePackQuantity"
                  isRequired={true}
                  onChange={this.handleChange}
                  error={this.state.salesCPQStatus}
                  errorText="Sales Case Pack Quantity is mandatory."
                />
                <InputField
                  isLabel={true}
                  value={this.state.purchaseMasterCartonPackQuantity}
                  type="text"
                  lable="Purchase Master Carton Pack Quantity"
                  name="purchaseMasterCartonPackQuantity"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.salesMasterCartonPackQuantity}
                  type="text"
                  lable="Sales Master Carton Pack Quantity"
                  name="salesMasterCartonPackQuantity"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.palletQuantity}
                  type="text"
                  lable="Pallet Quantity"
                  name="palletQuantity"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.gstRate}
                  type="text"
                  lable="GST Rate"
                  name="gstRate"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.hsnCode}
                  type="text"
                  lable="HSN Code"
                  name="hsnCode"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.priceApplicableFrom}
                  type="text"
                  lable="Price Applicable From"
                  name="priceApplicableFrom"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.priceApplicableTo}
                  type="text"
                  lable="Price Applicable To"
                  name="priceApplicableTo"
                  onChange={this.handleChange}
                />
              </FieldCard>

              {/* media */}
              <FieldCard take="1/4" heading={"Media"}>
                {this.state.image.length === 0 ? (
                  <div className={bStyles.media_container}>
                    <FontAwesomeIcon icon={faPlus} color="#36619b" />
                    <p>Add Media</p>
                    <input type="file" onChange={this.handleImage} />
                  </div>
                ) : (
                  <div className={bStyles.medias}>
                    {this.state.image.map((val: any, index) => {
                      return (
                        <div className={bStyles.media} key={index}>
                          <img
                            src={
                              this.props.router.params.id
                                ? val.obj !== ""
                                  ? val.url
                                  : process.env.REACT_APP_IMAGE + val.url
                                : val.url
                            }
                            alt=""
                          />
                          <div
                            className={bStyles.media_close}
                            onClick={() => this.removeImage(index)}
                          >
                            <FontAwesomeIcon icon={faClose} />
                          </div>
                        </div>
                      );
                    })}
                    <div className={bStyles.media}>
                      <div className={bStyles.add_more_media}>
                        <FontAwesomeIcon icon={faPlus} color="#36619b" />
                        <input type="file" onChange={this.handleImage} />
                      </div>
                    </div>
                  </div>
                )}
              </FieldCard>

              {/* Inventory */}
              <FieldCard take="1/4" heading="Inventory">
                <InputField
                  isLabel={true}
                  value={this.state.inventoryLevelMinimum}
                  type="text"
                  lable="Stock in hand"
                  name="inventoryLevelMinimum"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.inventoryLevelMinimum}
                  type="text"
                  lable="Inventory Level Minimum"
                  name="inventoryLevelMinimum"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.inventoryLevelMaximum}
                  type="text"
                  lable="Inventory Level Maximum"
                  name="inventoryLevelMaximum"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.reorderLevel}
                  type="text"
                  lable="Reorder Level"
                  name="reorderLevel"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.safetyStock}
                  type="text"
                  lable="Safety Stock"
                  name="safetyStock"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.orderMultiple}
                  type="text"
                  lable="Order Multiple"
                  name="orderMultiple"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.minimumOrderQuantity}
                  type="text"
                  lable="Minimum Order Quantity"
                  name="minimumOrderQuantity"
                  onChange={this.handleChange}
                />
                {/* <InputField
                  isLabel={true}
                  value={this.state.purchaseLeadTime}
                  type="text"
                  lable="Purchase Lead Time"
                  name="purchaseLeadTime"
                  onChange={this.handleChange}
                /> */}
                {/* <InputField
                  isLabel={true}
                  value={this.state.purchaseTransitTime}
                  type="text"
                  lable="Purchase Transit Time"
                  name="purchaseTransitTime"
                  onChange={this.handleChange}
                /> */}
                {/* <InputField
                  isLabel={true}
                  value={this.state.dutyStructure}
                  type="text"
                  lable="Duty Structure"
                  name="dutyStructure"
                  onChange={this.handleChange}
                /> */}
              </FieldCard>

              {/* Specification */}
              <FieldCard take={"1/4"} heading="Specification">
                {/* <InputField
                  isLabel={true}
                  value={this.state.sku}
                  type="text"
                  lable="SKU"
                  name="sku"
                  onChange={this.handleChange}
                /> */}
                <InputField
                  isLabel={true}
                  value={this.state.countryOfOrigin}
                  type="text"
                  lable="Country of Origin"
                  name="countryOfOrigin"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.countryOfShipment}
                  type="text"
                  lable="Country of Shipment"
                  name="countryOfShipment"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.madeUpMaterial}
                  type="text"
                  lable="Made Up Material"
                  name="madeUpMaterial"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.subMadeUpMaterial}
                  type="text"
                  lable="Sub Made up Material"
                  name="subMadeUpMaterial"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.color}
                  type="text"
                  lable="Color"
                  name="color"
                  isRequired={true}
                  onChange={this.handleChange}
                  error={this.state.colorStatus}
                  errorText="Color is mandatory."
                />
                <InputField
                  isLabel={true}
                  value={this.state.length}
                  type="text"
                  lable="Length"
                  name="length"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.width}
                  type="text"
                  lable="Width"
                  name="width"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.height}
                  type="text"
                  lable="Height"
                  name="height"
                  isRequired={true}
                  onChange={this.handleChange}
                  error={this.state.heightStatus}
                  errorText="Height is mandatory."
                />
                <InputField
                  isLabel={true}
                  value={this.state.depth}
                  type="text"
                  lable="Depth"
                  name="depth"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.diameter}
                  type="text"
                  lable="Diameter"
                  name="diameter"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.boxWeight}
                  type="text"
                  lable="Box Weight"
                  name="boxWeight"
                  onChange={this.handleChange}
                  // isRequired={true}
                />

                <InputField
                  isLabel={true}
                  value={this.state.boxLength}
                  type="text"
                  lable="Box Length"
                  name="boxLength"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.boxWidth}
                  type="text"
                  lable="Box Width"
                  name="boxWidth"
                  onChange={this.handleChange}
                />

                <InputField
                  isLabel={true}
                  value={this.state.boxHeight}
                  type="text"
                  lable="Box Height"
                  name="boxHeight"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.capacity}
                  type="text"
                  lable="Capacity"
                  name="capacity"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.thickness}
                  type="text"
                  lable="Thickness"
                  name="thickness"
                  onChange={this.handleChange}
                />
                <InputField
                  isLabel={true}
                  value={this.state.weight}
                  type="text"
                  lable="Weight"
                  name="weight"
                  onChange={this.handleChange}
                  isRequired={true}
                  error={this.state.weightStatus}
                  errorText="Weight is mandatory."
                />
                {/* <InputField
                  isLabel={true}
                  value={this.state.voltage}
                  type="text"
                  lable="Voltage"
                  name="voltage"
                  onChange={this.handleChange}
                /> */}
                {/* <InputField
                  isLabel={true}
                  value={this.state.power}
                  type="text"
                  lable="Power"
                  name="power"
                  onChange={this.handleChange}
                /> */}

                <div className={bStyles.txt_editor}>
                  <p>Collection Description</p>
                  <TextEditor
                    content={this.state.productSpecShort}
                    handleChange={(val: any) => {
                      this.setState({ productSpecShort: val });
                    }}
                    placeholder={"Add Body Content"}
                  />
                </div>

                <div className={bStyles.txt_editor}>
                  <p>Product Description</p>
                  <TextEditor
                    content={this.state.productSpecLong}
                    handleChange={(val: any) => {
                      this.setState({ productSpecLong: val });
                    }}
                    placeholder={"Add Body Content"}
                  />
                </div>
              </FieldCard>
            </FieldsContainer>
          </ContentBody>
        </Content>
      </>
    );
  }
}

export default withRouter(AddUpdateProduct);
