import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import styles from "../../styles/table.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { CiFilter } from "react-icons/ci";

type Props = {
  head: any;
  filtervalue?: any;
  //   onHeadClick: any;
  //   showIconAt?: any;
  //   icon?: any;
};

const TableHead = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dropdownVisibility, setDropdownVisibility]: any = useState({});
  const [selectedFilter, setSelectedFilter]: any = useState({
    Category: [],
    Collection: [],
  });

  useEffect(() => {
    const restoredData = RestoreFilterData();

    setSelectedFilter({ ...restoredData });
  }, []);

  const searchPar: any = new URLSearchParams(location.search);

  const RestoreFilterData = () => {
    const paramsObject: any = {};
    for (const [key, value] of searchPar.entries()) {
      // If the key already exists, convert it to an array
      if (paramsObject.hasOwnProperty(key)) {
        if (!Array.isArray(paramsObject[key])) {
          console.log(paramsObject[key]);
          paramsObject[key] = [paramsObject[key]];
        }
        paramsObject[key].push(value);
      } else {
        paramsObject[key] = [value];
      }
    }
    return paramsObject;
  };

  const handleFilterDropDown = (filterTitle: any) => {
    // Toggle the visibility of the dropdown for the clicked filter
    setDropdownVisibility((prevVisibility: any) => ({
      ...Object.fromEntries(
        Object.keys(prevVisibility).map((key) => [key, false])
      ),
      [filterTitle]: !prevVisibility[filterTitle],
    }));
  };

  const closeDropdown = () => {
    setDropdownVisibility((prevVisibility: any) => ({
      ...Object.fromEntries(
        Object.keys(prevVisibility).map((key) => [key, false])
      ),
    }));
  };

  const handleFilter = (e: any, title: any, value: any) => {
    // console.log(selectedFilter);
    // console.log(e.target.checked, title, value);
    if (e.target.checked) {
      let temp: any = selectedFilter[title] ? [...selectedFilter[title]] : [];
      temp.push(value);
      setSelectedFilter({ ...selectedFilter, [title]: temp });
    } else {
      let dump: any = [...selectedFilter[title]];
      dump.splice(selectedFilter[title].indexOf(value), 1);
      setSelectedFilter({ ...selectedFilter, [title]: dump });
    }
  };

  const constructSearchQuery = (updatedFilter?: any) => {
    console.log(updatedFilter);
    if (updatedFilter) setSelectedFilter({ ...updatedFilter });
    console.log(selectedFilter);
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    // console.log(url);
    url.search = "";

    // Add selected filters as search parameters
    Object.entries(selectedFilter).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          url.searchParams.append(key, val);
        });
      } else {
        // @ts-ignore
        url.searchParams.append(key, value);
      }
      // console.log(url);
      navigate(`${url.search}`, { replace: true });
    });

    return url.href;
  };

  const handleApply = (updatedFilter?: any) => {
    constructSearchQuery();
    closeDropdown();
  };

  // console.log(location.search);

  return (
    <thead style={{ zIndex: 100 }}>
      <tr>
        {props.head?.map((val: any, index: any) => (
          <th align="center" key={index}>
            {val.title}
            {val?.filter && (
              <>
                {!location.search.includes(val.title) ? (
                  <CiFilter
                    style={{
                      marginBottom: "-4px",
                      // border: "1px solid red",
                    }}
                    size={16}
                    onClick={() => handleFilterDropDown(val.title)}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faFilter}
                    color="grey"
                    onClick={() => handleFilterDropDown(val.title)}
                  />
                )}
                {dropdownVisibility[val.value] && (
                  <div className={styles.dropdown}>
                    {props.filtervalue[val.value]?.map(
                      (value: any, ind: any) => (
                        <div className={styles.drop_val}>
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            onClick={(e: any) =>
                              handleFilter(e, val.title, value)
                            }
                            checked={
                              selectedFilter[val?.value]?.includes(value)
                                ? true
                                : false
                            }
                          />
                          <p key={ind}>{value}</p>
                        </div>
                      )
                    )}
                    <div className={styles.dropdown_btn}>
                      <button onClick={handleApply}>APPLY</button>
                      {/* <button onClick={() => clearAll(val.title)}>
                            CLEAR ALL
                          </button> */}
                    </div>
                  </div>
                )}
              </>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
