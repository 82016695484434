import React, { useEffect, useState } from "react";
import Content from "../../../components/Content/Content";
import ContentHead from "../../../components/Content/ContentHead";
import ContentBody from "../../../components/Content/ContentBody";
import {
  TOAST_DELETE_ERROR,
  TOAST_DELETE_LOADING,
  TOAST_DELETE_SUCCESS,
  USER_TITLE,
} from "../../../utils/constants";
import api from "../../../api";
import Table from "../../../components/Table";
import {
  delete_text,
  users_table_body_key,
  users_table_head,
} from "../../../utils/data";
import Loading from "../../../components/Loading";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Modal from "../../../components/Modal";

let inFocus = "";

const Users = () => {
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api({
      url: "user",
      method: "GET",
      header: true,
    })
      .then((res: any) => {
        console.log(res.data.users);
        setUsers(
          res.data.users.map((val: any) => ({
            ...val,
            fullName: val.firstName + " " + val.lastName,
          }))
        );
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setUsers([]);
      });
  };

  const handleRowClick = (val: any) => {
    navigate("/user/" + val._id);
  };

  const handleDelete = () => {
    setModal(false);
    toast.promise(
      new Promise((resolve, reject) => {
        api({
          url: "user/" + inFocus,
          method: "DELETE",
          header: true,
        })
          .then(() => {
            inFocus = "";
            getData();
            resolve(true);
          })
          .catch(() => {
            inFocus = "";
            reject(false);
          });
      }),
      {
        loading: TOAST_DELETE_LOADING,
        success: TOAST_DELETE_SUCCESS,
        error: TOAST_DELETE_ERROR,
      }
    );
  };

  const handleActionClick = (_: string, id: string) => {
    setModal(true);
    inFocus = id;
  };

  const closeModal = () => {
    setModal(false);
    inFocus = "";
  };

  return (
    <>
      <Modal
        title={"Delete"}
        state={modal}
        description={delete_text}
        onBtn1Click={closeModal}
        onBtn2Click={handleDelete}
      />
      <Loading state={loading} />
      <Content>
        <ContentHead title={USER_TITLE} />
        <ContentBody style={{ padding: 0 }}>
          <Table
            body={users}
            head={users_table_head}
            body_keys={users_table_body_key}
            number={true}
            onActionClick={handleActionClick}
            onRowClick={handleRowClick}
          />
        </ContentBody>
      </Content>
    </>
  );
};

export default Users;
