import React, { useEffect, useState } from "react";
import Content from "../../../components/Content/Content";
import ContentHead from "../../../components/Content/ContentHead";
import ContentBody from "../../../components/Content/ContentBody";
import { ORDER_TITLE } from "../../../utils/constants";
import Loading from "../../../components/Loading";
import api from "../../../api";
import toast from "react-hot-toast";
import Table from "../../../components/Table";
import { orders_table_body_key, orders_table_head } from "../../../utils/data";
import { useNavigate } from "react-router-dom";
import pStyles from "../../../styles/products.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";
import styles from "../../../styles/order.module.css";

const Orders = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api({
      url: "order",
      method: "get",
      header: true,
    })
      .then((res: any) => {
        console.log(res.data.orders);
        setLoading(false);
        setOrders(res.data.orders);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        toast.error("Something went wrong while fetching orders.");
      });
  };

  const handleRowClick = (val: any) => {
    navigate("/orders/" + val._id);
  };

  return (
    <>
      <Loading state={loading} />
      <Content>
        <ContentHead title={<OrderHead />} customElement={<CustomPart />} />
        <ContentBody style={{ padding: 0 }}>
          <Table
            head={orders_table_head}
            body={orders}
            body_keys={orders_table_body_key}
            number={true}
            onActionClick={() => {}}
            onRowClick={handleRowClick}
          />
        </ContentBody>
      </Content>
    </>
  );
};

const OrderHead = () => {
  return (
    <div className={styles.order_head}>
      {ORDER_TITLE}
      <div className={styles.orders_type}>
        {/* <div className={styles.active}>All Orders</div> */}
        {/* <div>Completed</div> */}
        {/* <div>Cancelled</div> */}
        {/* <div>Failed</div> */}
      </div>
    </div>
  );
};

const CustomPart = () => {
  const [filterModal, setFilterModal] = useState(false);

  return (
    <div className={pStyles.custom_element} style={{ border: "none" }}>
      {/* <div className={pStyles.search_product_container}>
        <FontAwesomeIcon icon={faSearch} color="grey" />
        <input type="text" placeholder="Search Product" />
      </div> */}
      {/* <div
        className={pStyles.filter_container}
        style={{ cursor: "pointer" }}
        onClick={() => setFilterModal(true)}
      >
        <FontAwesomeIcon
          icon={faFilter}
          color="grey"
          onClick={(e: any) => {
            e.stopPropagation();
            setFilterModal(true);
          }}
        />
        Filter
        {filterModal && (
          <div className={pStyles.filter_elements_container}>
            <div className={pStyles.filter_modal_head}>
              <h3>Filter</h3>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  // setSelectedFilter((prev: any) => {
                  //   let temp: any = { ...prev };
                  //   Object.keys(temp).forEach((x) => {
                  //     temp[x] = [];
                  //   });
                  //   return temp;
                  // });
                }}
              >
                Clear
              </p>
            </div>
            <ul className={pStyles.filter_level_one_list}>
              {/* {Object.keys(filter).map((val, index) => {
                console.log(val);
                return (
                  <li key={index}>
                    <h4>
                      {val === "categories" && "Categories"}
                      {val === "productTypes" && "Product Types"}
                      {val === "color" && "Color"}
                      {val === "productSubGroup3" && "Product SubGroup3"}
                    </h4>
                    <ul className={styles.filter_level_two_list}>
                      {filter[val]
                        .filter((v: any) => v)
                        .map((m_val: any, iindex: any) => {
                          return (
                            <li
                              key={index}
                              onClick={() => {
                                let temp: any = selectedFilter;
                                if (temp[val].indexOf(m_val) !== -1) {
                                  temp[val].splice(temp[val].indexOf(m_val), 1);
                                  setSelectedFilter({ ...temp });
                                } else {
                                  temp[val].push(m_val);
                                  setSelectedFilter({ ...temp });
                                }
                              }}
                              style={
                                selectedFilter[val].indexOf(m_val) !== -1
                                  ? {
                                      background: "black",
                                      color: "white",
                                    }
                                  : {}
                              }
                            >
                              {m_val}
                            </li>
                          );
                        })}
                    </ul>
                  </li>
                );
              })} 
            </ul>
          </div>
        )}
        {filterModal && (
          <div
            onClick={(e: any) => {
              e.stopPropagation();
              setFilterModal(false);
            }}
            className={pStyles.close_surface}
          ></div>
        )}
      </div> */}
    </div>
  );
};

export default Orders;
