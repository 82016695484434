export const BLOG_TITLE = "Blog";
export const BLOG_PATH = "/";
export const BLOG_NEW = "Create New Blog";
export const BLOG_NEW_PATH = "/blogs/new";
export const BLOG_UPDATE = "Update Blog";
export const BLOG_UPDATE_PATH = "/blogs/update/:id";

export const PRODUCT_TITLE = "Product";
export const PRODUCT_PATH = "/products";
export const PRODUCT_NEW = "Create New Product";
export const PRODUCT_NEW_PATH = "/products/new";
export const PRODUCT_UPDATE = "Update Product";
export const PRODUCT_UPDATE_PATH = "/products/update/:id";

export const ORDER_TITLE = "Order";
export const ORDER_PATH = "/orders";
export const ORDER_DETAILS = "Order Detail";
export const ORDER_DETAILS_PATH = "/orders/:id";

export const JOB_TITLE = "Jobs";
export const JOB_PATH = "/jobs";
export const ASPIRANT_TITLE = "Aspirants";
export const ASPIRANT_PATH = "/aspirants";

export const USER_TITLE = "User";
export const USER_PATH = "/user";
export const USER_DETAILS = "User Details";
export const USER_DETAILS_PATH = "/user/:id";

export const COMPLIANCE_TITLE = "Compliance";
export const COMPLIANCE_PATH = "/compliance";
export const COMPLIANCE_NEW = "Create New Compliance";
export const COMPLIANCE_NEW_PATH = "/compliance/new";
export const COMPLIANCE_UPDATE = "Update Compliance";
export const COMPLIANCE_UPDATE_PATH = "/compliance/update/:id";

export const DISCOUNT_TITLE = "Discounts";
export const DISCOUNT_PATH = "/discounts";
export const DISCOUNT_NEW = "Create New Discount";
export const DISCOUNT_NEW_PATH = "/discounts/new";
export const DISCOUNT_UPDATE = "Update Discount";
export const DISCOUNT_UPDATE_PATH = "/discounts/update/:id";

export const TOAST_DELETE_LOADING = "Deleting....";
export const TOAST_DELETE_SUCCESS = "Deleted Successfully.";
export const TOAST_DELETE_ERROR = "Error Occurred While Deleting";
