import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../../styles/applayout.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { sideBar } from "../../../utils/data";

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation().pathname;

  useEffect(() => {
    document.addEventListener("click", (e: any) => {
      if (e.target.className && typeof e.target.className === "string") {
        if (
          e.target?.className.includes("menu_line") ||
          e.target?.className.includes("sidebar_main") ||
          e.target?.className.includes("menu_items_container") ||
          e.target?.className.includes("menu_icon_container")
        ) {
        } else {
          setOpen(false);
        }
      }
    });
  }, []);

  return (
    <div className={styles.sidebar_container}>
      <div
        className={styles.sidebar_main}
        style={{ width: open ? "200px" : "" }}
      >
        <div
          className={`${styles.menu_icon_container} ${
            open ? styles.open_menu_icon : ""
          }`}
          onClick={() => {
            setOpen((old) => !old);
          }}
        >
          {/* line 1 */}
          <div className={styles.menu_line_one}></div>
          {/* line 2 */}
          <div className={styles.menu_line_two}></div>
          {/* line 3 */}
          <div className={styles.menu_line_three}></div>
        </div>
        <ul className={styles.menu_items_container}>
          {sideBar
            .filter((val) => val.show)
            .map((val: any, index: any) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    setOpen(false);
                    navigate(val.path);
                  }}
                  className={`menu_mob_btn ${
                    val.path === location ? styles.selected : ""
                  }`}
                  // style={val.path === location ? { background: "blue" } : {}}
                >
                  <FontAwesomeIcon
                    icon={val.icon}
                    color={val.path === location ? "#1e1e1e" : "#7e7e7e"}
                  />
                  {/* <i className={"fa-address-book"}></i> */}
                  <span
                    className={`${styles.sidebar_text} ${
                      open && styles.sidebar_text_show
                    }`}
                  >
                    {val.title}
                  </span>
                </li>
              );
            })}

          <li
            onClick={() => {
              localStorage.removeItem("token-BO-ADMIN");
              localStorage.removeItem("blog");
              localStorage.removeItem("contact");
              localStorage.removeItem("career");
              localStorage.clear();
              navigate("/");
              window.location.reload();
            }}
          >
            <FontAwesomeIcon icon={faRightFromBracket} />
            <span
              className={`${styles.sidebar_text} ${
                open && styles.sidebar_text_show
              }`}
            >
              Logout
            </span>
          </li>
        </ul>
      </div>

      <div className={styles.sidebar_main_mob}>
        <div className={styles.mob_logo}>Logo</div>
        <div
          className={`${styles.menu_mob_btn} ${
            open ? styles.open_menu_icon : ""
          }`}
          onClick={() => {
            setOpen((val) => !val);
          }}
        >
          <div className={styles.menu_line_one}></div>
          <div className={styles.menu_line_two}></div>
          <div className={styles.menu_line_three}></div>
        </div>
        <div
          className={`${styles.mub_overlay} ${
            open ? styles.open_menu_mob : ""
          }`}
        >
          {open && (
            <ul className={styles.menu_items_container_mob}>
              {sideBar
                .filter((val) => val.show)
                .map((val: any, index: any) => {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        navigate(val.path);
                        setOpen((val) => !val);
                      }}
                      className={`${
                        val.path === location ? styles.selected : ""
                      }`}
                      // style={val.path === location ? { background: "blue" } : {}}
                    >
                      {open && <FontAwesomeIcon icon={val.icon} fill="green" />}
                      {/* <i className={"fa-address-book"}></i> */}
                      <span
                        className={`${styles.sidebar_text} ${
                          open && styles.sidebar_text_show
                        }`}
                      >
                        {val.title}
                      </span>
                    </li>
                  );
                })}

              <li
                onClick={() => {
                  localStorage.removeItem("token-BO-ADMIN");
                  localStorage.removeItem("blog");
                  localStorage.removeItem("contact");
                  localStorage.removeItem("career");
                  navigate("/");
                  window.location.reload();
                }}
              >
                {open && <FontAwesomeIcon icon={faRightFromBracket} />}
                <span
                  className={`${styles.sidebar_text} ${
                    open && styles.sidebar_text_show
                  }`}
                >
                  Logout
                </span>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
